import Footer from "../Components/Home/Footer";
import HeroAA from "../Components/adoracaoeartes/HeroAdoracaoeArtes";
import ConteudoAA from "../Components/adoracaoeartes/conteudoAdoracaoArtes";



function MinAA() {
    return (
        <div className='App'>
            <>
                <HeroAA />
                <ConteudoAA />
                <Footer />
            </>
        </div>
    );
}

export default MinAA;

