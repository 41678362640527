import React from 'react';
import Foto from '../../Images/Group 24.png';
import '../../Styles/conteudocjc.css'

function ConteudoCJC() {
    return (
        <div className="bg-white py-16 screen-padrao m-auto max-[1500px]:px-10 px-0">
            <div className="grid grid-cols-1 lg:grid-cols-[44%_minmax(58%,_1fr)_7%] gap-4">
                <div className='flex items-center grid grid-cols-1 content-normal'>
                    <p className='text-cjc'>
                        A Cruzada Juvenil Cristã (CJC) é um projeto que possui princípios de escostismo voltado para crianças e adolescentes dos 4 aos 16 anos. Com o objetivo de dar a oportunidade a cada criança de se tornar um soldado do Grande Exército do Deus Vivo, a base da CJC está em Lucas 2:52: "E crescia Jesus em sabedoria, estatura e em graça para com Deus e os homens".
                        <br /><br />
                        Possuí um currículo bem fundamentado que visa não só trazer conhecimento bíblico, mas foca em diversas áreas de desenvolvimento para que nossos soldados cresçam e estejam preparados para serem bons líderes diante da Igreja e da Sociedade.
                    </p>
                </div>
                <div className='items-center'>
                    <img src={Foto} alt='Foto do Ministerio infantil da igreja' className='ft-cjc mx-auto' />
                </div>
            </div>
            <div>
                <p className='pt-24 text-cjc1 text-center'>
                    <strong>Faça parte de nossa comunidade e experiente esse ambiente fantástico de cuidado mútuo</strong>
                </p>
            </div>
            <div className='pt-7 pb-10 text-center'>
                <a href='/#quero-ser-membro' className='botao-valores texto-botao-valores py-3 px-6'>
                    Quero ser membro
                </a>
            </div>
        </div>
    );
}

export default ConteudoCJC;