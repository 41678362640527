import React from 'react';
import Foto from '../../Images/valor1 1.png';
import Foto1 from '../../Images/valor2 1.png';
import Foto2 from '../../Images/valor3 1.png';
import Foto3 from '../../Images/valor4 1.png';
import Foto4 from '../../Images/valor5 1.png';
import '../../Styles/Valores.css'

function Valores() {
    return (
        <div className="bg-[#F9F9F9]">
            <div className='py-16 screen-padrao m-auto max-[1500px]:px-10 px-0'>
                <div className='text-center pb-12'>
                    <p className='title-valores'>Conheça nossos valores</p>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-5 gap-4">
                    <div className='items-center'>
                        <img src={Foto} alt='Sem texto' className='mx-auto ft-valores' />
                    </div>
                    <div className='items-center'>
                        <img src={Foto1} alt='Sem texto' className='mx-auto ft-valores' />
                    </div>
                    <div className='items-center'>
                        <img src={Foto2} alt='Sem texto' className='mx-auto ft-valores' />
                    </div>
                    <div className='items-center'>
                        <img src={Foto3} alt='Sem texto' className='mx-auto ft-valores' />
                    </div>
                    <div className='items-center'>
                        <img src={Foto4} alt='Sem texto' className='mx-auto ft-valores' />
                    </div>
                </div>
                <div className='pt-16 pb-16 text-center'>
                    <a href='/#quero-ser-membro' className='botao-valores texto-botao-valores py-3 px-6'>
                        Quero ser membro
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Valores;