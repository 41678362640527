import { Fragment, useState } from 'react';
import Modal from './Modal';
import mensagemVideo from '../../Images/mensagem_video.png';
import mensagemAudio from '../../Images/mensagem_audio.png';
import boletim from '../../Images/mensagem_boletim.png';
import deezer from '../../Images/deezer.png';
import spotify from '../../Images/spotify.png';
import '../../Styles/mensagem.css'


function Mensagens() {
    const [showModal, setShowModal] = useState(false);
    const isMobile = window.innerWidth <= 768;


    return (
        <Fragment>
            <div className="bg-white max-[768px]:py-10 py-24 screen-padrao m-auto max-[768px]:px-0 px-0">
                <div className="grid grid-cols-3 sm:grid-cols-3 lg:grid-cols-3 gap-4">
                    <div>
                        <a href='/videos'>
                            <img src={mensagemVideo} alt="Mensagem em vídeo" />
                        </a>
                    </div>
                    <div>
                        <button onClick={() => setShowModal(true)}>
                            <img src={mensagemAudio} className="w-full" width={"100%"} alt="Mensagem em áudio" />
                        </button>
                    </div>
                    <div>
                        <a href='/boletim' >
                            <img src={boletim} alt="Boletim" />
                        </a>
                    </div>
                </div>
            </div>
            {/* Modals */}

            <Modal isVisible={showModal} onClose={() => setShowModal(false)} isMobile={isMobile}>
                <div className='p-6 rounded-md' style={{ backgroundColor: "#fff" }}>
                    <div>
                        <h1 className='font-mensagem-h1 text-center'>Mensagem em áudio</h1>
                        <p className='pt-5 desc-eventos max-[768px]:text-center'>
                            Ouça agora nossas mensagens através do seu aplicativo de streaming de música preferido.
                        </p>
                    </div>
                    <div className='grid grid-cols-2 max-[649px]:grid-cols-1 gap-4 items-center py-24'>
                        <div className="max-[639px]:pb-5">
                            <a href='https://open.spotify.com/show/3RSo8nIBN8Kk6eHZZLxsKr?si=816575bd05364440'>
                                <img src={spotify} className="w-full" alt="Ouça agora no Spotify" />
                            </a>
                        </div>
                        <div>
                            <a href='https://deezer.page.link/zujzqNCCLy12caXQ6'>
                                <img src={deezer} className="w-full" alt="Ouça agora no Deezer" />
                            </a>
                        </div>
                    </div>
                    <div className='pt-3 text-center text-red-700 hover:text-red-950'>
                        <strong>
                            <button className='text-red text-2xl' onClick={() => setShowModal(false)}>Fechar</button>
                        </strong>
                    </div>
                </div>

            </Modal>

        </Fragment>
    );
}

export default Mensagens;
