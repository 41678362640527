import Footer from "../Components/Home/Footer";
import HeroInfantil from "../Components/min-infantil/HeroInfantil";
import ConteudoI from "../Components/min-infantil/conteudoI";




function MinInfantil() {
    return (
        <div className='App'>
            <>
                <HeroInfantil />
                <ConteudoI />
                <Footer />
            </>
        </div>
    );
}

export default MinInfantil;

