import React from 'react';
import Foto from '../../Images/mulheres 3 1.png';
import '../../Styles/conteudoM.css'

function ConteudoM() {
    return (
        <div className="bg-white py-16 screen-padrao m-auto max-[1500px]:px-10 px-0">
            <div className="grid grid-cols-1 lg:grid-cols-[44%_minmax(58%,_1fr)_7%] gap-4">
                <div className='flex items-center grid grid-cols-1 content-normal'>
                    <p className='text-mulher'>
                        O ministério de mulheres provê assistência espiritual à mulher para que ela exerça o seu papel de forma plena em diversas áreas de sua vida. Todos os momentos de encontro de mulheres são sempre edificantes e transformadores.
                        <br /><br />
                        O ministério atua promovendo: cursos voltado às mulheres, encontros periódicos, acompanhamento individual e em grupo e retiros.
                    </p>
                </div>
                <div className='items-center'>
                    <img src={Foto} alt='Foto do Ministerio de Mulheres da igreja' className='ft-mulher mx-auto' />
                </div>
            </div>
            <div>
                <p className='pt-24 text-mulher1 text-center'>
                    <strong>Faça parte de nossa comunidade e experiente esse ambiente fantástico de cuidado mútuo</strong>
                </p>
            </div>
            <div className='pt-7 pb-10 text-center'>
                <a href='/#quero-ser-membro' className='botao-valores texto-botao-valores py-3 px-6'>
                    Quero ser membro
                </a>
            </div>
        </div>
    );
}

export default ConteudoM;