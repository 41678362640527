import React from 'react';
import '../../Styles/block-2.css'

function Block2() {
  return (
    <div className="bg-white py-16 screen-padrao m-auto max-[1500px]:px-10 px-0">
      <div className='flex items-center grid grid-cols-1 content-normal'>
        <span className='text-block-2 text-center'>
          “Eu estava sem igreja e pedi à denominação o valor de 15 mil cruzeiros e comprei esse terreno em Vila Moraes. Juntamente com o pastor Manuel, alugamos um caminhão velho e fomos a São José do Rio Preto buscar um Tabernáculo de uma igreja que tinha sido desativada. Chegamos a Rio Preto numa terça feira. Na quinta já estávamos em São Paulo fazendo o alicerce e na sexta estávamos com a Tabernáculo montado. Foi um sucesso, pois contamos com a simpatia de alguns adolescentes que muito nos ajudaram e foram os primeiros convertidos. A inauguração estava marcada para o domingo, então eu e o pastor José Carlos Moura, com seu violão saímos com nossa Kombi fazendo convite e com tudo meio sem acabar direito, começamos o trabalho de Vila Moraes.”
        </span>
        <span className='text-block-2-n pt-10 text-center'>
          Pr. Syllas Antunes
        </span>
      </div>
    </div>
  );
}

export default Block2;