import Footer from "../Components/Home/Footer";
import HeroHomens from "../Components/min-homens/HeroHomens";
import ConteudoH from "../Components/min-homens/conteudoH";


function MinHomens() {
    return (
        <div className='App'>
            <>
                <HeroHomens />
                <ConteudoH />
                <Footer />
            </>
        </div>
    );
}

export default MinHomens;

