import Footer from "../Components/Home/Footer";
import HeroMulheres from "../Components/min-mulheres/HeroMulheres";
import ConteudoM from "../Components/min-mulheres/conteudoM";



function MinMulheres() {
    return (
        <div className='App'>
            <>
                <HeroMulheres />
                <ConteudoM />
                <Footer />
            </>
        </div>
    );
}

export default MinMulheres;

