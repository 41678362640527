import Footer from "../Components/Home/Footer";
import BoletinsList from "../Api/BoletinsList";
import Navbar2 from "../Components/Home/Navbar2";

function Boletim() {
    return (
        <div className='App'>
            <>
                <Navbar2 />
                <BoletinsList />
                <Footer />
            </>
        </div>
    );
  }
  
  export default Boletim;
  
  