import Navbar2 from "../Components/Home/Navbar2";
import Footer from "../Components/Home/Footer";
import EventosList from "../Api/AgendaList";

function Agenda() {
    return (
        <div className='App'>
            <>
                <Navbar2 />
                <EventosList />
                <Footer />
            </>
        </div>
    );
  }
  
export default Agenda;