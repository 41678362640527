import React from 'react';
import Foto from '../../Images/PR_1.png';
import Foto2 from '../../Images/pr_2.png';
import Foto3 from '../../Images/clarence owsley3.png';
import Foto4 from '../../Images/Pr. Expedito1.png';
import Foto5 from '../../Images/DOMINIL E SAULO 1.png';
import Foto6 from '../../Images/08 - PR DORI 1.png';
import Foto7 from '../../Images/09 - SAULO GARCIA MASSON 1.png';
import Foto8 from '../../Images/10 - PAULO CESAR DA SILVA SANTOS 1.png';
import '../../Styles/legado.css'

function Legado() {
    return (
        <div className="bg-white py-16 screen-padrao m-auto max-[1500px]:px-10 px-0">
            <div className='text-center pb-32'>
                <p className='legado-title'>Legados</p>
                <p className='legado-sub'>Cada pastor que passou por Vila Moraes deixou sua marca</p>
            </div>
            <div className='min[850px]:w-[701px] m-auto'>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    <div className='items-center'>
                        <img src={Foto} alt='Foto de Pastor Syllas Antunes' className='legado-foto1e2 mx-auto' />
                    </div>
                    <div className='items-center'>
                        <img src={Foto2} alt='Foto de pastor José Carlos Moura' className='legado-foto1e2 mx-auto' />
                    </div>
                </div>
            </div>
            <div className='text-center pt-16'>
                <p className='legado-text'>Pr. Syllas Antunes e Pr. José Carlos Moura</p>
                <p className='legado-text1 pt-10'>Ambos estiveram à frente da fundação desta igreja. <br />Foram os pioneiros.</p>
            </div>
            <div className='text-center pt-32'>
                <img src={Foto3} alt='Foto de pastor clarence owsley' className='legado-foto3 mx-auto' />
            </div>
            <div className='text-center pt-16'>
                <p className='legado-text'>Pr. Clarence Owsley</p>
                <p className='legado-text1 pt-10'>Consolidou a fé dos jovens membros.</p>
            </div>
            <div className='text-center pt-32'>
                <img src={Foto4} alt='Foto de pastor Expedito Vicente Calixto' className='legado-foto4 mx-auto' />
            </div>
            <div className='text-center pt-16'>
                <p className='legado-text'>Pr. Expedito Vicente Calixto</p>
                <p className='legado-text1 pt-10'>Assumiu a responsabilidade de não permitir que a igreja <br className='max-[768px]:hidden' /> fosse fechada e construiu o prédio, além de ter feito<br className='max-[768px]:hidden' /> um forte trabalho de evangelização no bairro.</p>
            </div>
            <div className='text-center pt-32'>
                <img src={Foto5} alt='Foto de pastor Dominil Bento Paulino' className='legado-foto5 mx-auto' />
            </div>
            <div className='text-center pt-16'>
                <p className='legado-text'>Pr. Dominil Bento Paulino</p>
                <p className='legado-text1 pt-10'>
                    Trouxe uma excelente estrutura organizacional nos ministérios da igreja local consolidando e<br className='max-[768px]:hidden' />  amadurecendo a liderança. Foi em seu ministério que a igreja se despertou para uma visão missionária<br className='max-[768px]:hidden' />  sendo fundado o ministério de missões com a primeira diretoria conduzida pelo casal Dani e Ricardo.<br className='max-[768px]:hidden' />  Estiveram apoiando seu ministério os pastores Nelson José Malta e José Raul Pereira.
                </p>
            </div>
            <div className='text-center pt-32'>
                <img src={Foto6} alt='Foto de pastor Dorivaldo Puerta Masson' className='legado-foto6 mx-auto' />
            </div>
            <div className='text-center pt-16'>
                <p className='legado-text'>Pr. Dorivaldo Puerta Masson</p>
                <p className='legado-text1 pt-10'>
                    O legado de seu ministério foi a reforma do prédio, a aquisição da casa e do apartamento pastoral. Em<br className='max-[768px]:hidden' /> seu ministério a igreja cresceu em seu patrimônio e em membros. Em seu ministério recebeu o apoio da<br className='max-[768px]:hidden' /> Pastora Dalzira que trouxe um forte carisma acerca da vida de oração. Também estiveram o auxiliando e<br className='max-[768px]:hidden' /> contribuindo para o apoio de seu ministério os pastores Edson Ribeiro, Josué Lamônica, Pastor José Roberto,<br className='max-[768px]:hidden' /> Pastor Ângelo Pássaro e candidatos ao ministério que hoje servem como pastores como O <br className='max-[768px]:hidden' />Pastor Isaías Francisco, Pastor Samuel Cecílio, Pastor Alexandre Oliveira, Pastor Paulo César, <br className='max-[768px]:hidden' />Pastor Ivanílson, Pastor Robson, Pastor Fabio Elton, Pastora Débora e Pastor Rodrigo.
                </p>
            </div>
            <div className='text-center pt-32'>
                <img src={Foto7} alt='Foto de pastor Saulo Garcia Masson' className='legado-foto7 mx-auto' />
            </div>
            <div className='text-center pt-16'>
                <p className='legado-text'>Pr. Saulo Garcia Masson</p>
                <p className='legado-text1 pt-10'>
                    Despertou a igreja para o valor dos pequenos grupos.
                </p>
            </div>
            <div className='text-center pt-32'>
                <img src={Foto8} alt='Foto de pastor Paulo Cesar' className='legado-foto8 mx-auto' />
            </div>
            <div className='text-center pt-16 pb-10'>
                <p className='legado-text'>Pr. Paulo Cesar</p>
                <p className='legado-text1 pt-10'>
                    Trouxe à igreja uma visão renovada e focada no ensino e na exposição das Escrituras.
                </p>
            </div>
        </div>
    );
}

export default Legado;