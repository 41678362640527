import Footer from "../Components/Home/Footer";
import HeroProjetos from "../Components/Projetos/HeroProjetos";
import ConteudoProj from "../Components/Projetos/conteudoProjetos";





function Projetos() {
    return (
        <div className='App'>
            <>
                <HeroProjetos />
                <ConteudoProj />
                <Footer />
            </>
        </div>
    );
}

export default Projetos;
