import React from 'react';
import Foto from '../../Images/clarence owsley 1.png';
import '../../Styles/block-4.css'

function Block4() {
  return (
    <div className="bg-white py-16 screen-padrao m-auto max-[1500px]:px-10 px-0">
      <div className="grid grid-cols-1 lg:grid-cols-[36%_minmax(64%,_1fr)_7%] gap-4">
        <div className='items-center'>
          <img src={Foto} alt='Foto de Clarence Owsley e sua esposa' className='Foto-bl4 mx-auto' />
        </div>
        <div className='flex items-center grid grid-cols-1 content-normal'>
          <p className='text-block-4'>
            O <strong>Pastor Clarence Owsley</strong>, pai do Pastor Daniel Owsley, também passou por Vila Moraes. Poucos o compreendiam, pois, seu português era sofrível, mas Deus enviava cada vez mais jovens animados e alegres para esta congregação. Entretanto, nem tudo foi flores.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Block4;