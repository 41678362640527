import React from 'react';
import Foto from '../../Images/GALPAO_1.png';
import '../../Styles/Plantando.css';

function Plantando() {
    return (
        <div className="bg-[#F9F9F9]">
            <div className='py-16 screen-padrao m-auto max-[1500px]:px-10 px-0'>
                <div className='text-center pb-10'>
                    <img src={Foto} alt='Foto do galpão antigamente' className='w-full' />
                </div>
                <div className='text-center'>
                    <p className='plantando-title'>Plantando Igrejas</p>
                </div>
                <div className='text-left pt-16'>
                    <p className='plantando-text'>
                        A <strong>IMeL Vila Moraes</strong> não apenas é um celeiro de pastores, mas sempre foi uma igreja generosa plantando e apoiando plantação de igrejas.
                    </p>
                    <p className='plantando-text pt-5'>
                        Através do seu apoio nasceu a <strong>IMeL Jardim Reimbergue</strong> com o envio do jovem Pastor Everaldo Ormonde com o auxílio dos jovens Nilton Torres e Sandra, Ângela Gomes, Antonio Pereira e Marcia.
                    </p>
                    <p className='plantando-text pt-5'>
                        Também nasceu a <strong>IMeL Campanário</strong> com os irmãos Francisco e Amélia, o casal Cícero e Floriza, Pastor Antônio Maceno, e irmão Valmir Abreu.
                    </p>
                    <p className='plantando-text pt-5'>
                        Além dessas igrejas, também nasceu a <strong>IMeL Jardim Clímax</strong> com o envio do Pastor Dorivaldo na época.
                    </p>
                    <p className='plantando-text pt-5'>
                        A <strong>IMeL de Vila Moraes</strong> não é o seu prédio, mas o seu povo. Muitos pastores que visitaram nossa comunidade, dentre eles o Pastor Jonathan (Vale da Benção) e Pastor Marcos Napoli (Missão Frontiers Brazil) afirmam o mesmo: “esta igreja está represada”, isto é, o tamanho prédio está limitando o fluir das águas que Deus deseja transbordar. Historicamente essa é uma igreja que nasceu pela fé e na dependência de Deus, e assim ela deve continuar crescendo e avançando.
                    </p>
                    <p className='plantando-text pt-5'>
                        Por isso, atualmente sonhamos e planejamos ir para um prédio maior para que esse legado de homens e mulheres que passaram por nossa igreja possa tomar uma proporção maior e contribua para a expansão do Reino de Deus, pois, o prédio ficará, mas as vidas que alcançarmos, essas subirão. Ainda estamos escrevendo a história com o Senhor.
                    </p>
                    <p className='plantando-text pt-5'>
                        Que a <strong>IMeL Vila Moraes</strong> seja sempre um celeiro de pastores, uma igreja missionária, evangelística, saturada de oração, discipuladora, multiplicadora , bíblica e firmada no Senhor.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Plantando;