import '../../Styles/missoes.css';


function Missoes() {
    return (
        <div className='bg-missoes'>
            <div className='screen-padrao m-auto max-[768px]:px-10 px-5'>
                <div className="grid grid-cols-1 lg:grid-cols-[44%_minmax(58%,_1fr)_7%] gap-4">
                    <div className='max-[768px]:pt-5 pt-24'>
                        <h1 className='font-missoes'>
                            Missões
                        </h1>
                        <p className='pt-2 font-missoes-p'>
                            Conheça nossos projetos sociais
                        </p>
                        <div className='max-[768px]:pt-7 pt-10'>
                            <a href='/missoes-transculturais' className='botao-agenda texto-botao-missao py-3 max-[768px]:px-6 px-6 w-[440px]'>
                                Missões Transculturais
                            </a>
                        </div>
                        <div className='max-[768px]:pt-7 pt-10'>
                            <a href='/projetos' className='botao-agenda texto-botao-missao py-3 max-[768px]:px-12 px-16 w-[440px]'>
                                Projetos Sociais
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Missoes;