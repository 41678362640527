import React, { useEffect, useState } from "react";
import axios from "axios";
import '../Styles/VideosList.css'

function VideosList() {
    const [videos, setVideos] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedVideo, setSelectedVideo] = useState(null);

    useEffect(() => {
        // Faça a solicitação GET à sua API PHP de vídeos
        axios
            .get("https://www.imelvilamoraes.com.br/sistema/api_videos.php")
            .then((response) => {
                // Formate as datas antes de definir os dados no estado
                const formattedVideos = response.data.map((video) => ({
                    ...video,
                    // Format the date here
                    data_postagem: formatDate(video.data_postagem), // Assuming 'data_postagem' is the date field
                }));
                setVideos(formattedVideos);
            })
            .catch((error) => {
                console.error("Erro ao buscar vídeos:", error);
            });
    }, []);

    // Função para formatar a data
    const formatDate = (dateString) => {
        const [day, month, year] = dateString.split("/");
        const formattedDate = new Date(`${year}-${month}-${day}`);
        const options = { year: "numeric", month: "numeric", day: "numeric" };
        return formattedDate.toLocaleDateString("pt-BR", options);
    };

    // Itens por página e cálculos de índices
    const itemsPerPage = 8;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentVideos = videos.slice(indexOfFirstItem, indexOfLastItem);

    // Função para avançar para a próxima página
    const nextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    // Função para voltar para a página anterior
    const prevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    // Função para ir para uma página específica
    const goToPage = (page) => {
        setCurrentPage(page);
    };

    // Cálculo do número total de páginas
    const totalPages = Math.ceil(videos.length / itemsPerPage);

    // Função para abrir o modal com o vídeo selecionado
    const openModal = (video) => {
        setSelectedVideo(video);
    };

    // Função para fechar o modal
    const closeModal = () => {
        setSelectedVideo(null);
    };

    const handleClose = (e) => {
        if (e.target.id === 'wrapper' || e.target.id === 'modal-container' || e.target.closest('#modal-container') === null) {
            closeModal();
        }
    };

    return (
        <div className="screen-padrao m-auto max-[768px]:px-10 px-10">
            <div className="pt-16 pb-16">
                <h2 className="title-eventos">Mensagens em vídeo</h2>
                <p className="desc-eventos">Assista agora nossas mensagens</p>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 pb-10">
                {currentVideos.map((video) => (
                    <div className="max-[640px]:py-0 py-10" key={video.id}>
                        <div className="flex justify-center items-center">
                            {/* Renderize a miniatura do vídeo aqui */}
                            <button onClick={() => openModal(video)}>
                                <img src={"https://www.imelvilamoraes.com.br/sistema/" + video.arquivo_thumb} className="w-full" alt={video.nome_video} />
                            </button>
                        </div>
                    </div>
                ))}
            </div>
            <nav aria-label="Page navigation example">
                <ul className="flex items-center -space-x-px h-8 text-sm justify-center pb-16">
                    {currentPage !== 1 && (
                        <li>
                            <button
                                onClick={prevPage}
                                className="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                            >
                                <span className="sr-only">Previous</span>
                                <svg className="w-2.5 h-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 1 1 5l4 4" />
                                </svg>
                            </button>
                        </li>
                    )}
                    {Array.from({ length: totalPages }, (_, index) => (
                        <li key={index}>
                            <button
                                onClick={() => goToPage(index + 1)}
                                className={`flex items-center justify-center px-3 h-8 leading-tight ${currentPage === index + 1
                                    ? "text-blue-600 border border-blue-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                                    : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                    }`}
                            >
                                {index + 1}
                            </button>
                        </li>
                    ))}
                    {currentPage !== totalPages && (
                        <li>
                            <button
                                onClick={nextPage}
                                className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                            >
                                <span className="sr-only">Next</span>
                                <svg className="w-2.5 h-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                </svg>
                            </button>
                        </li>
                    )}
                </ul>
            </nav>
            {selectedVideo && (
                <div className="container_modal fixed inset-0 z-50 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center" onClick={handleClose} id="wrapper">
                    <div className="absolute inset-0 bg-black opacity-70 "></div>
                    <div className="relative z-10 overflow-y-auto max-[639px]:w-[100%] w-[75%] flex flex-col p-2 max-[639px]:h-[650px] m-auto">
                        <button onClick={closeModal} className="text-white text-3xl place-self-end">×</button>
                        <div className="p-3 rounded-md w-full m-auto" style={{ backgroundColor: "#fff" }}>
                            <div className="aspect-video">
                                <iframe
                                    width={"100%"}
                                    height={"100%"}
                                    src={'https://www.youtube.com/embed/' + selectedVideo.link_video}
                                    title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default VideosList;