import Footer from "../Components/Home/Footer";
import VideosList from "../Api/VideosList";
import Navbar1 from "../Components/Home/Navbar1";


function Videos() {
    return (
        <div className='App'>
            <>
                <Navbar1 />
                <VideosList />
                <Footer />
            </>
        </div>
    );
  }
  
export default Videos;