import React from 'react';
import Foto from '../../Images/logo-bom-combate 1.png';
import Foto1 from '../../Images/Group 21.png';
import Foto2 from '../../Images/logo_ambassadors 1.png';
import Foto3 from '../../Images/Group 22.png';
import '../../Styles/conteudoProj.css';

function ConteudoProj() {
    return (
        <div className="bg-white py-16 screen-padrao m-auto max-[1500px]:px-10 px-0">
            <div className='flex items-center grid grid-cols-1 content-normal'>
                <p className='text-proj text-center pb-16'>
                    Conheça agora alguns dos projetos sociais apoiados pela Imel Vila Moraes
                </p>
            </div>
            <div className='flex items-center grid grid-cols-1 content-normal pb-16'>
                <img src={Foto} alt='Logo do Projeto Bom Combate artes marciais' className='ft-proj mx-auto' />
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-[37%_minmax(58%,_1fr)_7%] gap-4">
                <div className='items-center lg:hidden'>
                    <img src={Foto1} alt='Foto do Projeto Bom Combate artes marciais' className='ft-proj1 mx-auto' />
                </div>
                <div className='flex items-center grid grid-cols-1 content-normal'>
                    <p className=''>
                        <div className='text-proj2 pb-5'>
                            O projeto bom combate é uma ONG que tem como objetivo levar arte marcial de qualidade para todos. Através deste projetos, centenas de crianças e jovens tiveram suas vidas transformadas, algumas inclusive participando de campeonatos oficiais e ganhando medalhas.
                        </div>
                        <div className='text-proj2 pb-10'>
                            Por ser uma organização sem fins lucrativos, o projeto se mantém por meio de doações de seus apoiadores.
                        </div>

                        <div className='text-proj3'>
                            Seja um apoiador do nosso projeto!
                        </div>
                        <div className='text-proj4 pb-4'>
                            Chave Pix: 08.185.401/0001-75
                        </div>
                        <div className='text-proj5 min-[768px]:w-[350px] m-auto'>
                            Ao doar, acrescente R$ 0,10 para identificação da doação para o projeto
                        </div>
                    </p>
                </div>
                <div className='items-center max-[1023px]:hidden'>
                    <img src={Foto1} alt='Foto do Projeto Bom Combate artes marciais' className='ft-proj1 mx-auto' />
                </div>
            </div>
            <div className='flex items-center grid grid-cols-1 content-normal py-36'>
                <img src={Foto2} alt='Logo do Projeto Ambassadors football' className='ft-proj2 mx-auto' />
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-[55%_minmax(40%,_1fr)_7%] gap-4">
                <div className='items-center'>
                    <img src={Foto3} alt='Foto do Projeto Ambassadors football' className='ft-proj1 mx-auto' />
                </div>
                <div className='flex items-center grid grid-cols-1 content-normal'>
                    <p className=''>
                        <div className='text-proj2 pb-5'>
                        O Ambassadors Football é um projeto internacional o qual visa ensinar os fundamentos do futebol e, ao mesmo tempo compartilhar o evangelho de Jesus com os alunos do projeto.
                        </div>
                        <div className='text-proj2 pb-10'>
                        Atualmente o projeto atua em mais de 20 países e com muita alegria foi abraçado pela nossa comunidade. Nosso projeto já tem ajudado centenas de crianças e também depende de apoiadores para se manter.
                        </div>

                        <div className='text-proj3'>
                            Seja um apoiador do nosso projeto!
                        </div>
                        <div className='text-proj4 pb-4'>
                            Chave Pix: 08.185.401/0001-75
                        </div>
                        <div className='text-proj5 min-[768px]:w-[350px] m-auto'>
                            Ao doar, acrescente R$ 0,15 para identificação da doação para o projeto
                        </div>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default ConteudoProj;