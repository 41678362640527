import React from 'react';
import Foto from '../../Images/Pr. Expedito.png';
import '../../Styles/block-5.css'

function Block5() {
  return (
    <div className="bg-block5">
      <div className='py-16 screen-padrao m-auto max-[1500px]:px-10 px-0'>
        <div className="grid grid-cols-1 lg:grid-cols-[65%_minmax(36%,_1fr)_7%] gap-4">
          <div className='flex items-center grid grid-cols-1 content-normal'>
            <p className='text-block-5-t pb-7'>
              Entre o fechamento e a consolidação
            </p>
            <p className='text-block-5'>
              A congregação cheia de jovens simplesmente não podia se manter e surgiu a triste decisão de fechá-la. Foi quando o Pastor <strong>Expedito</strong> direcionado por Deus deixou a sua confortável condição de pastor auxiliar da IMeL de Mirandópolis para assumir em 1976 a congregação de Vila Moraes. Como ele mesmo testemunha, dedicou-se a visitar cada barraco da comunidade em volta daquela pequena congregação e com a graça de Deus ele construiu o templo de alvenaria.
            </p>
          </div>
          <div className='flex items-center grid grid-cols-1 content-normal'>
            <img src={Foto} alt='Foto do Pastor Expedito' className='Foto-bl5 mx-auto' />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Block5;