import Footer from "../Components/Home/Footer";
import HeroNL from "../Components/Nossa-lideranca/HeroNL";
import Pastores from "../Components/Nossa-lideranca/Pastores";


function NossaLideranca() {
    return (
        <div className='App'>
            <>
                <HeroNL />
                <Pastores />
                <Footer />
            </>
        </div>
    );
  }
  
  export default NossaLideranca;
  
  