import '../../Styles/seja-membro.css';
import { useState } from "react";

const SejaMembro = () => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [formData, setFormData] = useState({
    nome: "",
    email: "",
    cel: ""
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch("https://www.imelvilamoraes.com.br/sistema/api_email.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(formData),
      });

      const jsonResponse = await response.json();

      if (response.ok && jsonResponse.success) {
        setShowSuccessMessage(true);
        setTimeout(() => setShowSuccessMessage(false), 3000);

        // Limpa os campos do formulário
        setFormData({
          nome: "",
          email: "",
          cel: ""
        });
      } else {
        console.error("Erro ao enviar os dados.");
      }
    } catch (error) {
      console.error("Erro ao enviar os dados:", error);
    }
  };

  return (
    <div className='bg-seja' id='quero-ser-membro'>
      <div className='backgroud-seja'>
        <div className='screen-padrao m-auto max-[768px]:px-10 px-5'>
          <div class="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-4 py-32 max-[768px]:py-14">
            <div className='flex items-center grid grid-cols-1 content-normal sm:text-center'>
              <p className='font-seja-p'>
                <span className='font-seja'>Seja membro!</span><br />
                <span className=''>Faça parte da nossa comunidade de discípulos que refletem o caráter de Cristo!</span>
              </p>
            </div>
            <div className='bg-form-seja'>
              <div className='flex justify-center sm:text-center items-center pt-10 px-7'>
                <p className='font-missoes-p1'>
                  Quero ser membro da Imel Vila Moraes!
                </p>
              </div>
              <form onSubmit={handleSubmit}>
                {showSuccessMessage && (
                  <div className="bg-green-200 text-green-700 px-4 py-2 mb-4 rounded-md">
                    Mensagem enviada com sucesso!
                  </div>
                )}
                <div className="pt-7 max-w-2xl px-7 m-auto">
                  <input type="text" id="base-input" name="nome" className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ft_regular" placeholder="Nome" value={formData.nome}
                    onChange={handleChange}
                  />
                </div>
                <div className="pt-2 max-w-2xl px-7 m-auto">
                  <input type="text" id="base-input" name="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ft_regular" placeholder="E-mail" value={formData.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="pt-2 max-w-2xl px-7 m-auto">
                  <input type="text" id="base-input" name="cel" className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ft_regular" placeholder="(&nbsp;&nbsp;&nbsp;) 99999-9999" value={formData.cel}
                    onChange={handleChange}
                  />
                </div>
                <div className="pt-7 px-7 text-center pb-10 m-auto">
                  <button type="submit" className="botao-agenda text-white focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg text-xl px-8 py-4 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Enviar dados</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SejaMembro;
