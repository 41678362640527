import Footer from "../Components/Home/Footer";
import HeroMVV from "../Components/MissaoVisaoValores/HeroMVV";
import TemosMV from "../Components/MissaoVisaoValores/TemosMV";
import Valores from "../Components/MissaoVisaoValores/Valores";

function MissaoVisaoValores() {
    return (
        <div className='App'>
            <>
                <HeroMVV />
                <TemosMV />
                <Valores />
                <Footer />
            </>
        </div>
    );
}

export default MissaoVisaoValores;

