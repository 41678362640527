import React, { useEffect, useState } from "react";
import axios from "axios";
import ImgPng from "../Images/pdf 1.png";
import "../Styles/Boletins.css";

function BoletinsList() {
    const [boletins, setBoletins] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    // Define a function to format the date
    const formatDate = (dateString) => {
        // Divide a string da data nos componentes ano, mês e dia
        const [year, month, day] = dateString.split("-");

        // Cria uma nova data com os componentes
        const formattedDate = new Date(year, month - 1, day);

        // Formata a data como desejado (por exemplo, "dd/mm/yyyy")
        const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        return formattedDate.toLocaleDateString('pt-BR', options);
    };

    useEffect(() => {
        // Faça a solicitação GET à sua API PHP
        axios.get("https://www.imelvilamoraes.com.br/sistema/api_boletins.php")
            .then((response) => {
                // Formate as datas antes de definir os dados no estado
                const formattedBoletins = response.data.map((boletim) => ({
                    ...boletim,
                    // Format the date here
                    data: formatDate(boletim.data), // Assuming 'data' is the date field
                }));
                setBoletins(formattedBoletins);
            })
            .catch((error) => {
                console.error("Erro ao buscar boletins:", error);
            });
    }, []); // Empty dependency array means this effect runs only once

    const itemsPerPage = 8;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentBoletins = boletins.slice(indexOfFirstItem, indexOfLastItem);

    const nextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const prevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const goToPage = (page) => {
        setCurrentPage(page);
    };

    // Calcular o número total de páginas
    const totalPages = Math.ceil(boletins.length / itemsPerPage);

    return (
        <div className="screen-padrao m-auto max-[768px]:px-10 px-10">
            <div className="pt-16 pb-32">
                <h2 className="title-boletim">Boletim</h2>
                <p className="desc-boletim">Baixe agora o arquivo em pdf do boletim com nossas mensagens</p>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 pb-10">
                {currentBoletins.map((boletim) => (
                    <div className="py-10" key={boletim.id}>
                        <div className="flex justify-center items-center">
                            <img src={ImgPng} className="" width={"102px"} alt="Icone PDF" />
                        </div>
                        <div className="flex justify-center items-center pt-5">
                            <a href={"https://www.imelvilamoraes.com.br/sistema/" + boletim.localizacao_arquivo} target="_blank" download rel="noreferrer">
                                <p className="font_boletins">
                                    {boletim.data} - {boletim.tema}
                                </p>
                            </a>
                        </div>
                    </div>
                ))}
            </div>
            <nav aria-label="Page navigation example">
                <ul className="flex items-center -space-x-px h-8 text-sm justify-center pb-16">
                    {currentPage !== 1 && (
                        <li>
                            <button
                                onClick={prevPage}
                                className="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                            >
                                <span className="sr-only">Previous</span>
                                <svg className="w-2.5 h-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 1 1 5l4 4" />
                                </svg>
                            </button>
                        </li>
                    )}
                    {Array.from({ length: totalPages }, (_, index) => (
                        <li key={index}>
                            <button
                                onClick={() => goToPage(index + 1)}
                                className={`flex items-center justify-center px-3 h-8 leading-tight ${currentPage === index + 1
                                        ? "text-blue-600 border border-blue-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                                        : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                    }`}
                            >
                                {index + 1}
                            </button>
                        </li>
                    ))}
                    {currentPage !== totalPages && (
                        <li>
                            <button
                                onClick={nextPage}
                                className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                            >
                                <span className="sr-only">Next</span>
                                <svg className="w-2.5 h-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                </svg>
                            </button>
                        </li>
                    )}
                </ul>
            </nav>
        </div>
    );
}

export default BoletinsList;