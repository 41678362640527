import Footer from "../Components/Home/Footer";
import HeroMis from "../Components/MissaoTranscultural/HeroMis";
import ConteudoMis from "../Components/MissaoTranscultural/conteudoMis";




function Missoestransculturais() {
    return (
        <div className='App'>
            <>
                <HeroMis />
                <ConteudoMis />
                <Footer />
            </>
        </div>
    );
  }
  
  export default Missoestransculturais;
