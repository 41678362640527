import React, { useEffect } from 'react';
import '../../Styles/Modal.css'

const Modal = ({ isVisible, onClose, isMobile, children }) => {
    const handleClose = (e) => {
        if (e.target.id === 'wrapper') onClose();
    };

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape') {
                onClose();
            }
        };

        document.body.addEventListener('keydown', handleKeyDown);

        return () => {
            document.body.removeEventListener('keydown', handleKeyDown);
        };
    }, [onClose]);


    return isVisible ? (
        <div className='container_modal fixed inset-13 z-50 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center' onClick={handleClose} id="wrapper">
            <div className='overflow-y-auto w-[680px] flex flex-col p-2 max-[639px]:h-[650px] m-auto'>
                
                <div>
                    {children}
                </div>
            </div>
        </div>
    ) : null;
};

export default Modal;