import React, { useEffect, useRef } from 'react';
import '../../Styles/Hero.css';
import LogoBranco from '../../Images/logo_vlmoraes_branco 1.png';
import Navbar from './Navbar';

function Hero() {
  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch(error => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  return (
    <div className="background-hero bg-black h-[90vh] max-[320px]:h-[350px] max-[360px]:h-[365px]  max-[375px]:h-[370px] max-[393px]:h-[380px] max-[412px]:h-[380px] max-[414px]:h-[380px] max-[428px]:h-[380px] max-[768px]:h-[650px] max-[800px]:h-[450px] max-[820px]:h-[460px] max-[834px]:h-[469px] max-[884px]:h-[497px]" style={{ maxHeight: "90vh" }}>
      <Navbar />
      <div className="relative isolate px-6 lg:px-8">

        <div className="
        mx-auto 
        max-w-2x1 
        px-0 
        sm:px-5
        pt-[17rem] 
        pb-[15rem] 
        max-[320px]:pb-[4rem] 
        max-[320px]:pt-[4rem] 
        max-[360px]:pb-[5rem] 
        max-[360px]:pt-[5rem] 
        max-[393px]:pb-[5rem] 
        max-[393px]:pt-[5rem] 
        max-[768px]:pb-[6rem] 
        max-[768px]:pt-[6rem] 
        max-[1000px]:pb-[6rem] 
        max-[1000px]:pt-[6rem]
        max-[1024px]:pb-[10rem] 
        max-[1024px]:pt-[10rem] 
        max-[1114px]:pb-[10rem] 
        max-[1114px]:pt-[10rem] 
        max-[1280px]:pb-[10rem] 
        max-[1280px]:pt-[10rem]
        max-[1440px]:pb-[10rem] 
        max-[1440px]:pt-[10rem]
        max-[1680px]:pb-[15rem] 
        max-[1680px]:pt-[15rem]
        max-[1920px]:pb-[15rem] 
        max-[1920px]:pt-[15rem]
        m-auto">
          <div className="mx-auto px-0">
            <img src={LogoBranco} className="w-[450px] max-[640px]:w-[150px] m-auto" alt="Mensagem em vídeo" />
          </div>
        </div>
        <div className="mx-auto px-0 sm:px-5  max-[640px]:pb-0 m-auto">
          <div className="text-center">
            <p className='font-banner'>
              Rua Sebastiano Mazzoni, 288 - Vila Moraes - São Paulo
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
