import React from 'react';
import '../../Styles/Hero-sobre.css';
import Navbar from '../Home/Navbar';
import Foto from '../../Images/BANNER_NOSSA_MISSAO.png';
import Foto1 from '../../Images/BANNER_NOSSA_MISSAO_MOBILE.png';

function HeroMVV() {
  return (
    <div className="bg-black">
      <div className='bg-hero relative'>
        <div className="relative isolate">
          <div className="absolute inset-x-0 -z-10 transform-gpu overflow-hidden">
            <img src={Foto} alt='Foto do começo da igreja' className='max-[640px]:hidden w-full mx-auto' />
            <img src={Foto1} alt='Foto do começo da igreja' className='min-[641px]:hidden w-full mx-auto' />
          </div>
          <Navbar />
          <div className="mx-auto max-w-2x1 pd1-title max-[1200px]:px-10 m-auto screen-padrao">
            <div className="text-left">
              <span className='title-hero'>
                Missão, Visão e Valores
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="mb1-hero">
        &nbsp;
      </div>
    </div>
  );
}

export default HeroMVV;