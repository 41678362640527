import React from 'react';
import Sillas from '../../Images/Syllas.png';
import '../../Styles/block-1.css'

function Block1() {
  return (
    <div className="bg-white py-16 screen-padrao m-auto max-[1500px]:px-10 px-0">
      <div className="grid grid-cols-1 lg:grid-cols-[36%_minmax(64%,_1fr)_7%] gap-4">
        <div className='items-center'>
          <img src={Sillas} alt='Foto de Syllas Antunes e sua esposa' className='Foto-sillas mx-auto' />
        </div>
        <div className='flex items-center grid grid-cols-1 content-normal'>
          <p className='text-block-1'>
            No ano de 1972 um homem chamado <strong>Syllas Antunes</strong> desejou iniciar uma igreja no bairro de Vila Moraes. A IMeL de Mirandópolis, na direção de Deus, decidiu apoiar o Pr Syllas que esteve acompanhado pelos jovens Valdir e Bete Ruiz, José Carlos Moura e Sandra dentre outros irmãos que se dispuseram a investir no trabalho. 
          </p>
        </div>
      </div>
    </div>
  );
}

export default Block1;