import Footer from "../Components/Home/Footer";
import HeroPequenosGrupos from "../Components/pequenos-grupos/HeroPequenosGrupos";
import ConteudoPG from "../Components/pequenos-grupos/conteudoPG";





function PequenosGrupos() {
    return (
        <div className='App'>
            <>
                <HeroPequenosGrupos />
                <ConteudoPG />
                <Footer />
            </>
        </div>
    );
}

export default PequenosGrupos;
