import Footer from "../Components/Home/Footer";
import HeroJovens from "../Components/min-jovens/HeroJovens";
import ConteudoJ from "../Components/min-jovens/conteudoJ";



function MinJovens() {
    return (
        <div className='App'>
            <>
                <HeroJovens />
                <ConteudoJ />
                <Footer />
            </>
        </div>
    );
}

export default MinJovens;

