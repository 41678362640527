import React from 'react';
import Foto from '../../Images/Group 19.png';
import '../../Styles/conteudoJ.css'

function ConteudoJ() {
    return (
        <div className="bg-white py-16 screen-padrao m-auto max-[1500px]:px-10 px-0">
            <div className="grid grid-cols-1 lg:grid-cols-[44%_minmax(58%,_1fr)_7%] gap-4">
                <div className='flex items-center grid grid-cols-1 content-normal'>
                    <p className='text-jovem'>
                        O ministério dos jovens oferece apoio e cuidado com o adolescente, além de orientá-lo diante de diversas questões que fazem parte do cotidiano jovem.
                        <br /><br />
                        A proposta é trabalhar no coração do jovem para que ele possa ter uma vida cristã saudável e equilibrada além de interagir com a sociedade dentro de um modelo de comportamento bíblico e saudável.
                    </p>
                </div>
                <div className='items-center'>
                    <img src={Foto} alt='Foto do Ministerio de Jovens da igreja' className='ft-jovem mx-auto' />
                </div>
            </div>
            <div>
                <p className='pt-24 text-jovem1 text-center'>
                    <strong>Faça parte de nossa comunidade e experiente esse ambiente fantástico de cuidado mútuo</strong>
                </p>
            </div>
            <div className='pt-7 pb-10 text-center'>
                <a href='/#quero-ser-membro' className='botao-valores texto-botao-valores py-3 px-6'>
                    Quero ser membro
                </a>
            </div>
        </div>
    );
}

export default ConteudoJ;