import Footer from "../Components/Home/Footer";
import HeroSobre from "../Components/Sobre-nos/HeroSobre";
import Block1 from "../Components/Sobre-nos/block-1";
import Block2 from "../Components/Sobre-nos/block-2";
import Block3 from "../Components/Sobre-nos/block-3";
import Block4 from "../Components/Sobre-nos/block-4";
import Block5 from "../Components/Sobre-nos/block-5";
import Block6 from "../Components/Sobre-nos/block-6";
import Legado from "../Components/Sobre-nos/legado";
import Plantando from "../Components/Sobre-nos/plantando-igrejas";
import VideoHis from "../Components/Sobre-nos/nossa-his-video";

function Sobre() {
    return (
        <div className='App'>
            <>
                <HeroSobre />
                <Block1 />
                <Block2 />
                <Block3 />
                <Block4 />
                <Block5 />
                <Block6 />
                <Legado />
                <Plantando />
                <VideoHis />
                <Footer />
            </>
        </div>
    );
  }
  
  export default Sobre;
  
  