import React, { useState, useEffect } from "react";
import Logo from '../../Images/logo_imelvilamoraes 2.png';
import { Link } from 'react-scroll';
import { NavLink } from 'react-router-dom';
import '../../Styles/Navbar.css';
import { XMarkIcon, ChevronUpIcon, ChevronDownIcon  } from '@heroicons/react/24/outline';
import { Dialog } from '@headlessui/react';

const navigation = [
  { name: 'INÍCIO', href: '/' },
  {
    name: 'SOBRE NÓS', submenus: [
      { name: '• Nossa história', href: '/sobre-nos' },
      { name: '• Missão, Visão e Valores', href: '/missao-visao-valores' },
      { name: '• Nossa Liderança', href: '/nossa-lideranca' },
    ]
  },
  { name: 'MINISTÉRIOS',  submenus: [
    { name: '• Infantil', href: '/min-infantil' },
    { name: '• Jovens', href: '/min-jovens' },
    { name: '• Homens', href: '/min-homens' },
    { name: '• Mulheres', href: '/min-mulheres' },
    { name: '• Pequenos Grupos', href: '/pequenos-grupos' },
    { name: '• Cruzada Juvenil Cristã', href: '/min-cjc' },
    { name: '• Adoração e Artes ', href: '/adoracao-e-artes' },
    { name: '• Projetos Sociais', href: '/projetos' },
    { name: '• Missões Transculturais', href: '/missoes-transculturais' },
  ]
},
  { name: 'NOSSOS EVENTOS', href: '/agenda' },
];

function Navbar() {
  const [scrolled, setScrolled] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showSubmenus, setShowSubmenus] = useState({ name: null });

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 10) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navClass = scrolled ? "sticky-nav" : "";


  const openSubmenus = () => {
    setShowSubmenus(true);
  };

  const closeSubmenus = () => {
    setShowSubmenus(false);
  };

  const toggleSubmenus = (item) => {
    if (showSubmenus && showSubmenus.name === item.name) {
      setShowSubmenus({ name: null });
    } else {
      setShowSubmenus(item);
    }
  }

  return (
    <div>
    <nav className={`border-gray-200 max-[1500px]:px-10 px-0 transition duration-300 ease-in-out ${scrolled ? "bg-opacity-100 bg-white" : "bg-opacity-0"} ${navClass}`}>
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto py-1 screen-padrao">
        <a href="/" className="flex">
          <img src={Logo} className="max-[640px]:h-10 max-[640px]:mr-4 mr-7 h-16" alt="Metodista Livre Vila Moraes" />
        </a>
        <button
                data-collapse-toggle="navbar-default"
                type="button"
                tabIndex="0"
                className={`inline-flex items-center p-2 w-10 h-10 justify-center text-sm ${scrolled ? "dark-blue" : "text-white"} rounded-lg md:hidden hover:text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200`}
                aria-controls="navbar-default"
                aria-expanded={mobileMenuOpen ? "true" : "false"}
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              >

                <span className="sr-only">Toggle mobile menu</span>
                <svg
                  className={`w-5 h-5 ${mobileMenuOpen ? 'hidden' : ''}`} // Esconde o ícone quando o menu está aberto
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 17 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M1 1h15M1 7h15M1 13h15"
                  />
                </svg>
                <svg
                  className={`w-5 h-5 ${mobileMenuOpen ? '' : 'hidden'}`} // Mostra o ícone quando o menu está aberto
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 17 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 1H1M15 7H1M15 13H1"
                  />
                </svg>
              </button>

        <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={() => setMobileMenuOpen(false)}>
          <div className="fixed inset-0 z-40" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-40 w-full overflow-y-auto px-10 py-2 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10" style={{ backgroundColor: "#fff" }}>
            <div className="flex items-center justify-between">
              <a href="#inicio" className="-m-1.5 p-1.5">
                <span className="sr-only">IMeL Vila Moraes</span>
                <img src={Logo} className="max-[640px]:h-10 h-16" alt="Logo" />
              </a>
              <button type="button" className="-m-2.5 rounded-md p-2.5 text-black pr-4" onClick={() => setMobileMenuOpen(false)}>
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-8 w-8 text-black" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 pr-2 flow-root">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <div key={item.name}>
                    <div className="flex items-center justify-between">
                      <a
                        href={item.href}
                        onClick={() => setMobileMenuOpen(false)}
                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                        smooth={true}
                        duration={500}
                      >
                        {item.name}
                      </a>
                      {item.submenus && item.submenus.length > 0 && (
                        <button className="text-gray-600 hover:text-gray-900" onClick={() => toggleSubmenus(item)}>
                          {showSubmenus && showSubmenus.name === item.name ? (
                            <ChevronUpIcon className="h-6 w-6" />
                          ) : (
                            <ChevronDownIcon className="h-6 w-6" />
                          )}
                        </button>
                      )}
                    </div>
                    {item.submenus && item.submenus.length > 0 && showSubmenus && showSubmenus.name === item.name && (
                      <ul className="pl-6">
                        {item.submenus.map((submenu) => (
                          <li key={submenu.name} className="py-3">
                            <a
                              href={submenu.href}
                              onClick={() => setMobileMenuOpen(false)}
                              className="block text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                            >
                              <> {submenu.name}</>
                            </a>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>


        <div className="hidden w-full md:block md:w-auto" id="navbar-default">
          <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg  md:flex-row md:space-x-8 md:mt-0 md:border-0">
            {navigation.map((item, index) => (
              <li key={index}>
                {item.submenus ? ( // Verifica se o item tem submenus
                  <div className="relative">
                    <button
                      className={`block py-2 pl-3 pr-4 ${scrolled ? "dark-blue" : "text-white"} hover:text-customOrange font-menu submenu-trigger`}
                      onMouseEnter={() => openSubmenus()}
                      onMouseLeave={() => closeSubmenus()}
                    >
                      {item.name}
                    </button>
                    {showSubmenus && (
                      <ul className={`submenus ${scrolled ? "bg-white" : "bg"}`} onMouseEnter={openSubmenus} onMouseLeave={closeSubmenus}>
                        {item.submenus.map((submenu, subIndex) => (
                          <li key={subIndex}>
                            <NavLink to={submenu.href} className="sub-item hover:text-customOrange">
                              {submenu.name}
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                ) : (
                  item.href.startsWith('/') ? (
                    <NavLink to={item.href} className={`block py-2 pl-3 pr-4 ${scrolled ? "dark-blue" : "text-white"}  hover:text-customOrange font-menu`} activeClassName="font-bold">
                      {item.name}
                    </NavLink>
                  ) : (
                    <Link to={item.href} className={`block py-2 pl-3 pr-4 ${scrolled ? "dark-blue" : "text-white"} hover:text-customOrange font-menu`} spy={true} smooth={true} duration={500} offset={-70}>
                      {item.name}
                    </Link>
                  )
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
    <nav className={` ${scrolled ? "h-nav" : "hidden"} `}>
      &nbsp;&nbsp;&nbsp;
    </nav>
    </div>
  );
}

export default Navbar;