import React from 'react';
import Foto from '../../Images/kids2 1.png';
import '../../Styles/conteudoI.css'

function ConteudoI() {
    return (
        <div className="bg-white py-16 screen-padrao m-auto max-[1500px]:px-10 px-0">
            <div className="grid grid-cols-1 lg:grid-cols-[44%_minmax(58%,_1fr)_7%] gap-4">
                <div className='flex items-center grid grid-cols-1 content-normal'>
                    <p className='text-infantil'>
                        Contando com diversos recursos como vídeos infantis, berçário e escola bíblica, o ministério infantil acompanha e ensina a criança no verdadeiro caminho cristão.
                        <br /><br />
                        A liderança conta com um apoio pedagógico cristão de qualidade e busca aprimorar constantemente seus conhecimentos para entregar o melhor para cada um dos pequeninos dentro da jornada com Cristo.
                    </p>
                </div>
                <div className='items-center'>
                    <img src={Foto} alt='Foto do Ministerio infantil da igreja' className='ft-infantil mx-auto' />
                </div>
            </div>
            <div>
                <p className='pt-24 text-infantil1 text-center'>
                    <strong>Faça parte de nossa comunidade e experiente esse ambiente fantástico de cuidado mútuo</strong>
                </p>
            </div>
            <div className='pt-7 pb-10 text-center'>
                <a href='/#quero-ser-membro' className='botao-valores texto-botao-valores py-3 px-6'>
                    Quero ser membro
                </a>
            </div>
        </div>
    );
}

export default ConteudoI;