import React from 'react';
import '../../Styles/ConteudoPrivacidade.css';

function ConteudoPrivacidade() {
    return (
        <div className="bg-white py-16 screen-padrao m-auto max-[1500px]:px-10 px-0">
            <div className='title-privacidade py-16'>
                Política de Privacidade e Proteção de Dados
            </div>
            <div className='text-privacidade'>
                <p>
                    <strong>Objetivo</strong><br />
                    A Política de Privacidade e a Proteção de Dados descritas neste documento estabelece como é feita a coleta, tratamento e transferência de informações dos dados trafegados pela instituição, comprometendo-nos com a transparência do tratamento de dados pessoais dos nossos usuários, respeitando a Constituição Federal, em legislações setoriais como no Marco Civil da Internet e Lei 13.709/2018 (Lei Geral de Proteção de Dados Pessoais).
                    <br /><br />
                    Este documento descreve as medidas tomadas pela IGREJA METODISTA LIVRE DO BRASIL para garantir estar em conformidade com a LGPD. Este controle se aplica a todas as pessoas e processos da instituição, incluindo diretores, funcionários, empregados, fornecedores e terceiros que tem acesso aos sistemas de informação da organização.
                    <br /><br />
                    <strong>Generalidades</strong><br /><br />
                    Agentes de tratamento – o controlador e o operador;
                    <br /><br />
                    Anonimização – utilização de meios técnicos razoáveis e disponíveis no momento do tratamento, por meio dos quais um dado perde a possibilidade de associação, direta ou indireta, a um indivíduo;
                    <br /><br />
                    Banco de dados – conjunto estruturado de dados pessoais, estabelecido em um ou em vários locais, em suporte eletrônico ou físico;
                    <br /><br />
                    Controlador – pessoa natural ou jurídica, de direito público ou privado, a quem competem as decisões referentes ao tratamento de dados pessoais;
                    <br /><br />
                    Cookies – são arquivos ou informações que podem ser armazenadas em seus dispositivos (computador ou celular) quando você visita os websites ou utiliza os serviços on-line, contendo, em regra, o nome do site que o originou, o seu tempo de visita ao site e uma numeração, que é gerada aleatoriamente. Os COOKIES, refletem o seu histórico de navegação e interesses na internet;
                    <br /><br />
                    Dado anonimizado – dado relativo ao titular que não possa ser identificado, considerando a utilização de meios técnicos razoáveis e disponíveis na ocasião de seu tratamento;
                    Dado pessoal – informação relacionada a pessoa natural identificada ou identificável;
                    Dado pessoal sensível – dado pessoal sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural;
                    <br /><br />
                    Encarregado – pessoa indicada pelo controlador e operador para atuar como canal de comunicação entre o controlador, os titulares dos dados e a Autoridade Nacional de Proteção de Dados (ANPD);
                    <br /><br />
                    Operador – pessoa natural ou jurídica, de direito público ou privado, que realiza o tratamento de dados pessoais em nome do controlador;
                    <br /><br />
                    Tratamento – toda operação realizada com dados pessoais, como as que se referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração;
                    Titular – pessoa natural a quem se referem os dados pessoais que são objeto de tratamento.
                    <br /><br />
                    <strong>Responsabilidade</strong><br /><br />
                    As responsabilidades dos agentes que atuam nos processos de tratamento de dados, são previstas em conformidade com os artigos 42 ao 45 da Lei Geral de Proteção de Dados, nos comprometemos em manter esta Política de Privacidade atualizada, observando suas disposições e zelando por seu cumprimento, garantir condições técnicas e organizativas seguramente aptas a proteger todo o processo de tratamento de dados.
                    <strong>Isenção de Responsabilidade</strong><br /><br />
                    Embora adotemos elevados padrões de segurança a fim de evitar incidentes, não há nenhuma página virtual inteiramente livre de riscos. Nesse sentido, a IGREJA METODISTA LIVRE DO BRASIL DE VILA MORAES não se responsabiliza por:
                    <br /><br />
                    I – Quaisquer consequências decorrentes da negligência, imprudência ou imperícia dos usuários em relação a seus dados individuais. Garantimos e nos responsabilizamos apenas pela segurança dos processos de tratamento de dados e do cumprimento das finalidades descritas no presente instrumento.
                    <br /><br />
                    II – Destacamos que a responsabilidade em relação à confidencialidade dos dados de acesso é do usuário.
                    <br /><br />
                    III – Ações maliciosas de terceiros, como ataques de hackers, exceto se comprovada conduta culposa ou deliberada das operações contratadas na IGREJA METODISTA LIVRE DO BRASIL DE VILA MORAES.
                    <br /><br />
                    IV – Destacamos que em caso de incidentes de segurança que possam gerar risco ou dano relevante para você ou qualquer um de nossos usuários/clientes, comunicaremos aos afetados e a Autoridade Nacional de Proteção de Dados sobre o ocorrido e cumpriremos as providências necessárias.
                    <br /><br />
                    V – Inveracidade das informações inseridas pelo usuário/cliente nos registros necessários para a utilização dos nossos serviços; quaisquer consequências decorrentes de informações falsas ou inseridas de má-fé são de inteiramente responsabilidade do usuário.
                    <br /><br />
                    <strong>PRIVACIDADE E POLÍTICA DE PROTEÇÃO DE DADOS PESSOAIS</strong><br /><br />
                    <strong>A regulamentação da LGPD</strong><br /><br />
                    A lei geral de proteção de dados implica diretamente na maneira de como a IGREJA METODISTA LIVRE DO BRASIL DE VILA MORAES executa o processamento de dados na instituição. Consideráveis sanções podem ser cobradas caso ocorra um vazamento aplicado a LGPD. A política da IGREJA METODISTA LIVRE DO BRASIL DE VILA MORAES é garantir conformidade com a LGPD e outras regulamentações relevantes.
                    <br /><br />
                    <strong>Princípios relacionados ao processamento de dados privados</strong>
                    <br /><br />
                    Existem alguns princípios fundamentais sobre os quais a LGPD é baseada como: Finalidade, Adequação do tratamento à finalidade, Necessidade, Livre acesso, Transparência, Segurança, Prevenção, Não discriminação, Responsabilização e prestação de contas.
                    <br /><br />
                    A IGREJA METODISTA LIVRE DO BRASIL DE VILA MORAES garante conformidade com os princípios definidos na lei nas atividades de processamento.
                    <br /><br />
                    <strong>Dados coletados e finalidade</strong>
                    <br /><br />
                    A coleta e tratamento de dados pela IGREJA METODISTA LIVRE DO BRASIL DE VILA MORAES é realizada para cumprimento da obrigação legal junto aos órgãos fiscais e para atividade fim da instituição (divulgação de conteúdos e marketing) como previsto na Lei LGPD, quando necessário para a execução ou procedimentos preliminares relacionados a contrato de trabalho ou prestação de serviço do qual seja parte o titular e relações comerciais necessárias para realização dos negócios da companhia.
                    <br /><br />
                    <strong>Consentimento</strong>
                    <br /><br />
                    A IGREJA METODISTA LIVRE DO BRASIL DE VILA MORAES irá em todos os casos aplicáveis, obter explicitamente o consentimento de um titular para a coleta e processamento de seus dados. Em caso de menores de 18 anos, irá ser obtido consentimento dos pais/responsáveis. No momento da coleta do consentimento, serão apresentados que tipo de dados serão coletados, qual a finalidade do uso desses dados, tempo de retenção além de outras informações exigidas pela regulamentação. Essa informação será acessível e escrita em linguagem clara.
                    <br /><br />
                    <strong>Direitos do indivíduo</strong>
                    <br /><br />
                    O titular dos dados pessoais tem direito a obter do controlador, em relação aos dados do titular por ele tratados, a qualquer momento e mediante requisição:
                    <br /><br />
                    I – Confirmação da existência de tratamento;
                    <br /><br />
                    II – Acesso aos dados;
                    <br /><br />
                    III – Correção de dados incompletos, inexatos ou desatualizados;
                    <br /><br />
                    IV – Anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto na LGPD;
                    <br /><br />
                    V – Portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos comercial e industrial;
                    <br /><br />
                    VI – Eliminação dos dados pessoais tratados com o consentimento do titular, exceto nas hipóteses previstas no art. 16 da LGPD;
                    <br /><br />
                    VII – Informação das entidades públicas e privadas com as quais o controlador realizou uso compartilhado de dados;
                    <br /><br />
                    VIII – Informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa;
                    <br /><br />
                    IX – Revogação do consentimento, nos termos do § 5º do art. 8º da LGPD.
                    <br /><br />
                    Cada um destes direitos é apoiado por processos definidos pela IGREJA METODISTA LIVRE DO BRASIL DE VILA MORAES que permitem que a ação requerida seja tomada dentro do prazo definido pela LGPD.
                    <br /><br />
                    <strong>Interesses vitais do titular dos dados</strong>
                    <br /><br />
                    Em caso de proteção de interesses vitais do titular ou outra pessoa natural, os dados podem ser usados conforme as bases legais de processamento. A IGREJA METODISTA LIVRE DO BRASIL DE VILA MORAES garante reter razoavelmente evidências documentadas sempre que esse motivo for usado como base legal do processamento de dados pessoais.
                    <br /><br />
                    <strong>Como e por quanto tempo os dados serão armazenados</strong>
                    <br /><br />
                    Os dados pessoais coletados serão utilizados e armazenados durante o tempo necessário para a prestação do serviço ou para que as finalidades elencadas na presente Política de Privacidade sejam atingidas, considerando os direitos dos titulares dos dados e dos controladores.
                    Findado a relação contratual e o período de armazenamento, os dados pessoais serão excluídos de nossas bases de dados ou anonimizados, ressalvadas as hipóteses legalmente previstas em lei.
                    <br /><br />
                    <strong>Medidas de segurança aplicadas aos dados pessoais</strong>
                    <br /><br />
                    Para mantermos as informações pessoais seguras, usamos ferramentas físicas, eletrônicas e gerenciais orientadas para a proteção da privacidade. Todas as medidas aplicadas refletem o nosso compromisso com a segurança e privacidade dos dados pessoais, empregando soluções técnicas de proteção aptas a garantir a confidencialidade, integridade e inviolabilidade dos dados pessoais, apropriadas aos riscos e com controle de acesso às informações armazenadas, levando em consideração a natureza dos dados pessoais coletados, o contexto e a finalidade do tratamento e os riscos que eventuais violações gerariam para os direitos e liberdades do titular dos dados coletados e tratados.
                    <br /><br />
                    Em caso de incidentes de segurança que possa gerar risco ou dano relevante para qualquer titular, comunicaremos aos afetados e a Autoridade Nacional de Proteção de Dados sobre o ocorrido, em consonância com as disposições da Lei Geral de Proteção de Dados.
                    <br /><br />
                    <strong>Compartilhamento dos dados pessoais</strong>
                    <br /><br />
                    Tendo em vista a preservação de sua privacidade, a IGREJA METODISTA LIVRE DO BRASIL DE VILA MORAES não compartilhará dados pessoais com nenhum terceiro não autorizado, salvo hipótese de:
                    <br /><br />
                    I – Determinação legal, requerimento, requisição ou ordem judicial, com autoridades judiciais, administrativas ou governamentais competentes;
                    <br /><br />
                    II – Caso de movimentações societárias, como fusão, aquisição e incorporação, de forma automática;
                    <br /><br />
                    III – Proteção dos direitos da IGREJA METODISTA LIVRE DO BRASIL DE VILA MORAES em qualquer tipo de conflito, inclusive os de teor judicial.
                    <br /><br />
                    <strong>Contratos envolvendo processamento de dados pessoais</strong>
                    <br /><br />
                    A IGREJA METODISTA LIVRE DO BRASIL DE VILA MORAES irá garantir que todos os relacionamentos que envolvam processamento de dados relacionados a pessoas naturais estão sujeitos a um contrato documentado que inclui a informação específica, como por exemplo, a finalidade, os tipos de tratamentos, se serão compartilhados, quais porções de dados serão compartilhadas, para qual fim e com quem, cláusulas destacadas, consentimento positivo (inequívoco) e termos requeridos pela LGPD.
                    <br /><br />
                    <strong>Transferência internacional de dados pessoais</strong>
                    <br /><br />
                    Alguns dos terceiros com quem compartilhamos seus dados podem ser localizados ou possuir instalações localizadas em países estrangeiros. Nessas condições, de toda forma, seus dados pessoais estarão sujeitos à Lei Geral de Proteção de Dados e às demais legislações brasileiras de proteção de dados.
                    <br /><br />
                    Nesse sentido, a IGREJA METODISTA LIVRE DO BRASIL DE VILA MORAES se compromete a sempre adotar eficientes padrões de segurança cibernética e de proteção de dados, nos melhores esforços de garantir e cumprir as exigências legislativas.
                    <br /><br />
                    <strong>Cookies ou dados de navegação</strong>
                    <br /><br />
                    Cookies são arquivos de texto enviados pela plataforma ao seu computador e que nele se armazenam, são utilizados para aprimorar a experiência de uso, geralmente não contêm muitas informações, exceto pela url do site que criou o cookie, a duração das habilidades, efeitos do cookie e um número aleatório, ele não pode ser usado para revelar sua identidade ou identificar informações pessoalmente.
                    Ao acessar nosso site/plataforma e consentir com o uso de Cookies, você manifesta conhecer e aceitar a utilização de um sistema de coleta de dados de navegação com o uso de Cookies em seu dispositivo.
                    <br /><br />
                    <strong>Alteração desta política de privacidade</strong>
                    <br /><br />
                    Reservamos o direito de modificar essa Política de Privacidade a qualquer tempo, principalmente em função da adequação a eventuais alterações feitas em nosso site ou em âmbito legislativo. Recomendamos que você a revise com frequência, eventuais alterações entrarão em vigor a partir de sua publicação em nosso site.
                    <br /><br />
                    <strong>Encarregado de Proteção de Dados</strong>
                    <br /><br />
                    Caso tenha dúvidas sobre esta Política de Privacidade ou sobre os dados pessoais que tratamos, você pode entrar em contato conosco através do e-mail: <a href='mailto:contato@imelvilamoraes.com.br' style={{color: "#000", textDecoration: "none", fontWeight: "bold"}} >contato@imelvilamoraes.com.br</a>
                    <br /><br />
                    Ao concordar com essa Política de Privacidade, você concorda com todos os termos descritos para tratamento de dados pessoais.
                    <br /><br />
                    <br /><br />
                    São Paulo.<br />
                    IGREJA METODISTA LIVRE DO BRASIL DE VILA MORAES
                </p>
            </div>
        </div>
    );
}

export default ConteudoPrivacidade;