import React from 'react';
import Foto from '../../Images/Group 18.png';
import '../../Styles/ConteudoAA.css'

function ConteudoAA() {
    return (
        <div className="bg-white py-16 screen-padrao m-auto max-[1500px]:px-10 px-0">
            <div className="grid grid-cols-1 lg:grid-cols-[44%_minmax(58%,_1fr)_7%] gap-4">
                <div className='flex items-center grid grid-cols-1 content-normal'>
                    <p className='text-AA'>
                        O ministério de Adoração & Artes é um dos apoios mais importantes de nossa comunidade em seus momentos de celebração.
                        <br /><br />
                        Acima de qualquer performance, todos os voluntários tem apenas um objetivo em comum: levar a presença de Deus aos corações de forma clara e com qualidade.
                        <br /><br />
                        Todos os membros, apesar de seu serviço no ministério, experimentam um ambiente de cuidado mútuo de forma constante e regular para que seus corações estejam no lugar certo e sua dedicação de forma equilibrada.
                    </p>
                </div>
                <div className='items-center'>
                    <img src={Foto} alt='Foto do Ministerio de Jovens da igreja' className='ft-AA mx-auto' />
                </div>
            </div>
            <div>
                <p className='pt-24 text-AA1 text-center'>
                    <strong>Faça parte de nossa comunidade e experiente esse ambiente fantástico de cuidado mútuo</strong>
                </p>
            </div>
            <div className='pt-7 pb-10 text-center'>
                <a href='/#quero-ser-membro' className='botao-valores texto-botao-valores py-3 px-6'>
                    Quero ser membro
                </a>
            </div>
        </div>
    );
}

export default ConteudoAA;