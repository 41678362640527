import React from 'react';

import './Styles/index.css';
import App from './App';
import './Styles/input.css';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom'; // Import BrowserRouter

ReactDOM.render(
  <Router> {/* Wrap your entire app with Router */}
    <App />
  </Router>,
  document.getElementById('root')
);

