import React from 'react';
import Foto from '../../Images/Pr 1.png';
import '../../Styles/block-6.css'

function Block6() {
  return (
    <div className="bg-white py-16 screen-padrao m-auto max-[1500px]:px-10 px-0">
      <div className="grid grid-cols-1 lg:grid-cols-[36%_minmax(64%,_1fr)_7%] gap-4">
        <div className='items-center'>
          <img src={Foto} alt='Foto de pastor Rodrigo Rodrigues Lima' className='Foto-bl6 mx-auto' />
        </div>
        <div className='flex items-center grid grid-cols-1 content-normal'>
          <p className='text-block-6-t'>
            Celeiro de pastores e líderes
          </p>
          <p className='text-block-6'>
            A IMeL Vila Moraes tem recebido uma graça especial de Deus para ser um celeiro de pastores e líderes. Pastores que servem ou serviram na Igreja Metodista Livre foram gerados nesta igreja. Pastor Everaldo Ormonde, Pastor Raul Pereira, o casal de pastores Dalzira e José Roberto, Pastor Clóvis, Pastor Alexandre Oliveira, Pastor Paulo César, o casal de pastores Fábio Elton e Débora, Pastor Samuel Cecílio, Pastora Nilzete Pereira, os irmãos e pastores Edson e Carmo Ribeiro, Pastor Adriano Oliveira e o atual pastor <strong>Rodrigo Rodrigues Lima</strong>.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Block6;