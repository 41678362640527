import React from 'react';
import Foto from '../../Images/gfdhfd.png';
import '../../Styles/TemosMV.css'

function TemosMV() {
  return (
    <div className="bg-white py-16 screen-padrao m-auto max-[1500px]:px-10 px-0">
      <div className="grid grid-cols-1 lg:grid-cols-[44%_minmax(58%,_1fr)_7%] gap-4">
        <div className='flex items-center grid grid-cols-1 content-normal'>
        <p className='text-mv'>
            <span className='title-mv pb-2'>Temos como nossa missão e visão...<br/><br/></span>
            <strong>Edificar</strong> uma <strong>igreja saudável</strong> onde cada membro é um <strong>discípulo</strong> e cada <strong>lugar</strong> uma <strong>extensão da igreja</strong> conquistando assim, nossa geração através de <strong>pequenos grupos</strong> que multiplicam o caráter de <strong>Cristo</strong>.
          </p>
        </div>
        <div className='items-center'>
          <img src={Foto} alt='Foto de Pessoas na igreja' className='ft-mv mx-auto' />
        </div>
      </div>
    </div>
  );
}

export default TemosMV;