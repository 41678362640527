import React from 'react';
import '../../Styles/nossa-his-video.css'

function VideoHis() {
    return (
        <div className="bg-white">
            <div className='py-16 screen-padrao m-auto max-[1500px]:px-10 px-0'>
                <div className='text-center pb-16'>
                    <p className='nhv-title'>Nossa história em vídeo</p>
                </div>
                <div className='aspect-video max-[640px]:w-[100%] w-[70%] m-auto mb-16'>
                    <iframe width={"100%"} height={"100%"} src={'https://www.youtube.com/embed/qNJ56_mPw60'} title="YouTube video player"
                        frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                    ></iframe>
                </div>
            </div>
        </div>
    );
}

export default VideoHis;