import './Styles/App.css';
import './Styles/input.css';
import './Styles/Fonts/roboto.css';
import './Styles/Colors/colors.css';
import Home from './Pages/Home';
import { Routes, Route } from "react-router-dom";
import Boletim from './Pages/Boletim';
import Agenda from './Pages/Agenda';
import Videos from './Pages/videos';
import Sobre from './Pages/sobre';
import MissaoVisaoValores from './Pages/Missao-visao-valores';
import NossaLideranca from './Pages/nossa-lideranca';
import MinHomens from './Pages/min-homens';
import MinMulheres from './Pages/min-mulheres';
import MinJovens from './Pages/min-jovens';
import MinInfantil from './Pages/min-infantil';
import PequenosGrupos from './Pages/pequenos-grupos';
import Projetos from './Pages/Projetos';
import PoliticaPrivacidade from './Pages/politica-privacidade';
import MinAA from './Pages/Adoracaoeartes';
import MinCJC from './Pages/cjc';
import Missoestransculturais from './Pages/missoes-transculturais';

function App() {
  return (
     <div className="App">
      <Routes>
        <Route index element={<Home />} />
        <Route path="/boletim" element={<Boletim />} />
        <Route path="/agenda" element={<Agenda />} />
        <Route path="/videos" element={<Videos />} />
        <Route path="/sobre-nos" element={<Sobre />} />
        <Route path="/missao-visao-valores" element={<MissaoVisaoValores />} />
        <Route path="/nossa-lideranca" element={<NossaLideranca />} />
        <Route path="/min-homens" element={<MinHomens />} />
        <Route path="/min-mulheres" element={<MinMulheres />} />
        <Route path="/min-jovens" element={<MinJovens /> } />
        <Route path="/min-infantil" element={<MinInfantil /> } />
        <Route path="/pequenos-grupos" element={<PequenosGrupos /> } />
        <Route path="/adoracao-e-artes" element={<MinAA /> } />
        <Route path="/projetos" element={<Projetos /> } />
        <Route path="/politica-privacidade" element={<PoliticaPrivacidade /> } />
        <Route path="/min-cjc" element={<MinCJC /> } />
        <Route path="/missoes-transculturais" element={<Missoestransculturais /> } />
      </Routes>
    </div>
  );
}

export default App;
