import React, { useEffect, useState } from "react";
import '../../Styles/agenda.css';

function Agenda() {
    const [eventos, setEventos] = useState([]);

    // Define a function to format the date
    const formatDate = (dateString) => {
        const [year, month, day] = dateString.split("-");
        const formattedDate = new Date(year, month - 1, day);
        const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        return formattedDate.toLocaleDateString('pt-BR', options);
    };

    useEffect(() => {
        // Faça a solicitação GET à sua API PHP
        fetch("https://www.imelvilamoraes.com.br/sistema/api_eventos.php")
            .then((response) => response.json())
            .then((data) => {
                // Formate as datas antes de definir os dados no estado
                const formattedEventos = data.map((evento) => ({
                    ...evento,
                    data_evento: formatDate(evento.data_evento), // Format the date here
                }));
                setEventos(formattedEventos.slice(0, 2)); // Pega apenas os dois primeiros eventos
            })
            .catch((error) => {
                console.error("Erro ao buscar eventos:", error);
            });
    }, []);

    return (
        <div className='bg-agenda'>
            <div className='backgroud-agenda'>
                <div className='pt-16 pb-10'>
                    <h1 className='font-agenda-h1'>
                        Nossos Eventos
                    </h1>
                </div>
                {eventos.length > 0 ? (
                    eventos.map((evento) => (
                        <div key={evento.data_evento}>
                            <div className='py-10' style={{ background: '#EFEFEF', }}>
                                <div className='screen-padrao m-auto px-5 2xl:px-0'>
                                    <h1 className='titulo-agenda'>
                                        {evento.nome_evento}
                                    </h1>
                                    <p className='texto-agenda'>
                                        {evento.data_evento}
                                    </p>
                                    <p className='texto-agenda'>
                                        {evento.horario}
                                    </p>
                                </div>
                            </div>
                            <div className='max-[768px]:hidden block'>
                                <img src={"https://www.imelvilamoraes.com.br/sistema/" + evento.arquivo_evento_pc} className='w-full h-full' alt={evento.nome_evento} />
                            </div>
                            <div className='max-[768px]:block hidden'>
                                <img src={"https://www.imelvilamoraes.com.br/sistema/" + evento.arquivo_evento_mobile} className='w-full h-full' alt={evento.nome_evento} />
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="text-center font-agenda-h1">
                        <p>Nenhum evento disponível no momento.</p>
                    </div>
                )}
                <div className='pt-16 pb-16 text-center'>
                    <a href='/agenda' className='botao-agenda texto-botao-agenda font-bold py-2 px-4 rounded-full'>
                        Veja todos os nossos eventos
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Agenda;