import React from 'react';
import Foto from '../../Images/homens 2.png';
import '../../Styles/conteudoH.css'

function ConteudoH() {
    return (
        <div className="bg-white py-16 screen-padrao m-auto max-[1500px]:px-10 px-0">
            <div className="grid grid-cols-1 lg:grid-cols-[44%_minmax(58%,_1fr)_7%] gap-4">
                <div className='flex items-center grid grid-cols-1 content-normal'>
                    <p className='text-h'>
                        O ministério de homens atua restaurando o papel do homem segundo os padrões bíblicos e o preparando para viver sua vida cristã em diversas áreas como casamento, paternidade, trabalho entre outras.
                        <br /><br />
                        A atuação do ministério de homens se dá por meio de diversas atividades em conjunto tais como: encontros semanais, retiros, acompanhamento individual e em grupo e cursos de hombridade.
                    </p>
                </div>
                <div className='items-center'>
                    <img src={Foto} alt='Foto do Ministerio de Homens da igreja' className='ft-h mx-auto' />
                </div>
            </div>
            <div>
                <p className='pt-24 text-men1 text-center'>
                    <strong>Faça parte de nossa comunidade e experiente esse ambiente fantástico de cuidado mútuo</strong>
                </p>
            </div>
            <div className='pt-7 pb-10 text-center'>
                <a href='/#quero-ser-membro' className='botao-valores texto-botao-valores py-3 px-6'>
                    Quero ser membro
                </a>
            </div>
        </div>
    );
}

export default ConteudoH;