import React from 'react';
import Foto from '../../Images/mapa mundo 1.png';
import Foto1 from '../../Images/mundo 1.png';
import Foto2 from '../../Images/business-woman-hand-putting-one-pound-coin-in-to-p-2023-11-27-04-51-57-utc 1.png';
import '../../Styles/conteudoMis.css';

function ConteudoMis() {
    return (
        <div className="bg-white py-16 screen-padrao m-auto max-[1500px]:px-10 px-0">
            <div className='flex items-center grid grid-cols-1 content-normal'>
                <p className='text-Mis text-left'>
                    A iMel Vila Moraes apoia e investe regularmente em missões transculturais por entender que isto é uma ordenança de Jesus para todos que desejam ser seu discípulo conforme está escrito em Mateus 28.18-20.
                </p>
            </div>
            <div className='flex items-center grid grid-cols-1 content-normal'>
                <p className='text-Mis6 text-center pt-24 max-[640px]:pt-10 max-[640px]:pb-10 pb-24'>
                    “Jesus, aproximando-se, falou-lhes, dizendo: <br /> — Toda a autoridade me foi dada no céu e na terra. Portanto, vão e façam discípulos de todas as nações, <br className='max-[1024px]:invisible' />  batizando-os em nome do Pai, do Filho e do Espírito Santo, ensinando-os a guardar todas as coisas que <br className='max-[1024px]:invisible' />  tenho ordenado a vocês. E eis que estou com vocês todos os dias até o fim dos tempos.” <br /> <strong>Mateus 28.18-20</strong>
                </p>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-[50%_minmax(52%,_1fr)_7%] gap-4 pb-10">
                <div className='items-center lg:hidden'>
                    <img src={Foto} alt='Foto do Mapa mundi' className='ft-Mis1 mx-auto' />
                </div>
                <div className='flex items-center grid grid-cols-1 content-normal'>
                    <p className=''>
                        <div className='text-proj2 pb-5'>
                            Parte de nossos recursos são destinados à diversos missionários espalhados tanto pelo Brasil quanto diversos países no mundo. <br />
                            Para preservarmos a identidade e o trabalho missionário, evitamos listar os lugares onde os mesmos estão atuando por conta da perseguição religiosa que acontece em algumas regiões do mundo.
                        </div>
                    </p>
                </div>
                <div className='items-center max-[1023px]:hidden'>
                    <img src={Foto} alt='Foto do Mapa mundi' className='ft-Mis1 mx-auto' />
                </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-[42%_minmax(40%,_1fr)_7%] gap-4">
                <div className='items-center'>
                    <img src={Foto1} alt='Foto de uma moça vendo um mapa' className='ft-Mis1 mx-auto' />
                </div>
                <div className='flex items-center grid grid-cols-1 content-normal'>
                    <p className=''>
                        <div className='text-proj2 pb-5'>
                            A cada relatodo dos nossos enviados, nossa comunidade celebra as conquistas do campo missionário e direcionar as orações e recursos nas dificuldades levantada por cada missionário. <br />
                            Com isso mantemos o trabalho sempre ativo e prevalecente ao redor do mundo.
                        </div>
                    </p>
                </div>
            </div>
            <div className='flex items-center grid grid-cols-1 content-normal pt-32'>
                <p className='text-Mis7 text-center' id='atitude'>
                    Sua atitude pode mudar muitas vidas!
                </p>
            </div>
            <div className='flex items-center grid grid-cols-1 content-normal pt-10'>
                <p className='text-Mis text-left'>
                    Com sua contribuição, nós podemos fazer muito mais! O nosso objetivo é fazer com que todos possam conhecer a Jesus de uma maneira plena e poderosa!
                </p>
                <p className='text-Mis text-left pt-8'>
                    Nos ajude a levar a Mensagem de Cristo aos mais diversos corações espalhados pelo Brasil e pelo mundo.
                </p>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-[60%_minmax(52%,_1fr)_7%] gap-4 pt-24 pb-16">
                <div className='items-center lg:hidden'>
                    <img src={Foto2} alt='Foto do Mapa mundi' className='ft-Mis1 mx-auto' />
                </div>
                <div className='flex items-center grid grid-cols-1 content-normal'>
                    <p className=''>
                        <div className='text-Mis7 pb-10'>
                            Dados para contribuição:
                        </div>
                        <div className='text-proj2 text-left pb-5'>
                            <strong>Banco Itaú</strong> <br />
                            Agência: 0844<br />
                            Conta: 06925-6<br />
                            Chave Pix: 08.185.401/0001-75<br />
                            Igreja Metodista Livre do Brasil de Vila Moraes
                        </div>
                    </p>
                </div>
                <div className='items-center max-[1023px]:hidden'>
                    <img src={Foto2} alt='Foto do Mapa mundi' className='ft-Mis2 mx-auto' />
                </div>
            </div>
        </div>
    );
}

export default ConteudoMis;