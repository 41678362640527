import Footer from "../Components/Home/Footer";
import HeroCJC from "../Components/min-cjc/HeroCjc";
import ConteudoCJC from "../Components/min-cjc/conteudoCjc";

function MinCJC() {
    return (
        <div className='App'>
            <>
                <HeroCJC />
                <ConteudoCJC />
                <Footer />
            </>
        </div>
    );
}

export default MinCJC;

