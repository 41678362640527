import React from 'react';
import Foto from '../../Images/foto antiga jovens 7.png';
import '../../Styles/block-3.css'

function Block3() {
  return (
    <div className="bg-block3">
     <div className='py-16 screen-padrao m-auto max-[1500px]:px-10 px-0'>
     <div className="grid grid-cols-1 lg:grid-cols-[54%_minmax(46%,_1fr)_7%] gap-4">
        <div className='flex items-center grid grid-cols-1 content-normal'>
          <p className='text-block-3'>
          Nossa amada igreja iniciou com muita luta e esforço. Em um dos diversos episódios sobre seu início, ao construírem um pequeno prédio de alvenaria, porém no dia seguinte a lage havia caído.  Muitos jovens foram tocados por Deus e abençoaram esta obra, como por exemplo, o irmão Emerson que doou todo o material de construção que havia separado para a construção de sua casa.
          </p>
        </div>
        <div className='items-center'>
          <img src={Foto} alt='Foto dos jovens da igreja' className='Foto-bl3 mx-auto' />
        </div>
      </div>
     </div>
    </div>
  );
}

export default Block3;