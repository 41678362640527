import { Fragment, useState } from 'react';
import Modal from '../Home/Modal';
import foto from '../../Images/Rodrigo.png';
import foto1 from '../../Images/Fabiano.png';
import foto2 from '../../Images/Adriano.png';
import foto3 from '../../Images/andrea_editada 1.png';
import foto4 from '../../Images/Lidia Paula 1.png';
import foto5 from '../../Images/giba_editad 1.png';
import '../../Styles/Pastores.css'


function Pastores() {
    const [showModal, setShowModal] = useState(false);
    const [showModal1, setShowModal1] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [showModal3, setShowModal3] = useState(false);
    const [showModal4, setShowModal4] = useState(false);
    const [showModal5, setShowModal5] = useState(false);
    return (
        <Fragment>
            <div className="bg-white py-16 screen-padrao m-auto max-[768px]:px-10 px-0">
                <div className='text-center pb-16'>
                    <p className='text-pastores'>
                        Conheça nossos pastores lideres que tem conduzido nossa comunidade <br className='max-[768px]:hidden' />  dentro da visão e direção estabelecida por Deus
                    </p>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                    <div className='text-center max-[768px]:pt-16'>
                        <img src={foto} className="ft-pastores p-5 mx-auto" alt="Foto do Pastor Rodrigo R. Lima" />
                        <p className='name-pastores pt-7'>
                            Rodrigo R. Lima
                        </p>
                        <p className='name-pastores-sub pb-4'>
                            Pastor titular
                        </p>
                        <button className='buttom-pastores buttom-pastores-font' onClick={() => setShowModal(true)}>
                            Saiba mais
                        </button>
                    </div>
                    <div className='text-center max-[768px]:pt-16'>
                        <img src={foto1} className="ft-pastores p-5 mx-auto" alt="Foto do Pastor Fabiano Novaes" />
                        <p className='name-pastores pt-7'>
                            Fabiano Novaes
                        </p>
                        <p className='name-pastores-sub pb-4'>
                            Pastor auxiliar
                        </p>
                        <button className='buttom-pastores buttom-pastores-font' onClick={() => setShowModal1(true)}>
                            Saiba mais
                        </button>
                    </div>
                    <div className='text-center max-[768px]:pt-16 sm:pt-16 lg:pt-0'>
                        <img src={foto2} className="ft-pastores p-5 mx-auto" alt="Foto do Pastor Adriano Oliveira" />
                        <p className='name-pastores pt-7'>
                            Adriano Oliveira
                        </p>
                        <p className='name-pastores-sub pb-4'>
                            Pastor auxiliar
                        </p>
                        <button className='buttom-pastores buttom-pastores-font' onClick={() => setShowModal2(true)}>
                            Saiba mais
                        </button>
                    </div>
                    <div className='text-center pt-16'>
                        <img src={foto3} className="ft-pastores p-5 mx-auto" alt="Foto do Pastor Rodrigo R. Lima" />
                        <p className='name-pastores pt-7'>
                            Andréa A. Lima
                        </p>
                        <p className='name-pastores-sub pb-4'>
                            Lider de mulheres
                        </p>
                        <button className='buttom-pastores buttom-pastores-font' onClick={() => setShowModal3(true)}>
                            Saiba mais
                        </button>
                    </div>
                    <div className='text-center max-[768px]:pt-16 pt-16'>
                        <img src={foto4} className="ft-pastores p-5 mx-auto" alt="Foto do Pastor Fabiano Novaes" />
                        <p className='name-pastores pt-7'>
                            Lídia Paula
                        </p>
                        <p className='name-pastores-sub pb-4'>
                            Gestora Administrativa
                        </p>
                        <button className='buttom-pastores buttom-pastores-font' onClick={() => setShowModal4(true)}>
                            Saiba mais
                        </button>
                    </div>
                    <div className='text-center max-[768px]:pt-16 pt-16 max-[768px]:pb-16'>
                        <img src={foto5} className="ft-pastores p-5 mx-auto" alt="Foto do Pastor Adriano Oliveira" />
                        <p className='name-pastores pt-7'>
                            Gilberto Oliveira
                        </p>
                        <p className='name-pastores-sub pb-4'>
                            Líder de casais
                        </p>
                        <button className='buttom-pastores buttom-pastores-font' onClick={() => setShowModal5(true)}>
                            Saiba mais
                        </button>
                    </div>
                </div>
            </div>

            {/* Modals */}

            <Modal isVisible={showModal} onClose={() => setShowModal(false)}>
                <div className='p-6 rounded-md' style={{ backgroundColor: "#fff" }}>
                    <div className='p-7'>
                        <p className='text-pastores-popup'>
                            <strong>Rodrigo Rodrigues Lima </strong> é pastor na Imel Vila Moraes, superintendente do Concílio Sul Paulista da denominação Metodista Livre e presidente do Seminário Biblico Wesleyano. <br /><br />
                            Seu coração é voltado para o cuidado de vidas, por isso contribui com a igreja em diversos programas voltado para o discipulado cristão e o cuidado com o coração de líderes e ministérios. <br /><br />
                            Rodrigo é casado com Andréa com quem tem dois filhos: Giovanna e Miguel.
                        </p>
                    </div>
                    <div className='pt-5 text-center text-red-700 hover:text-red-950'>
                        <strong>
                            <button className='text-red text-2xl' onClick={() => setShowModal(false)}>Fechar</button>
                        </strong>
                    </div>
                </div>
            </Modal>
            <Modal isVisible={showModal1} onClose={() => setShowModal1(false)}>
                <div className='p-6 rounded-md' style={{ backgroundColor: "#fff" }}>
                    <div className='p-7'>
                        <p className='text-pastores-popup'>
                            <strong>Fabiano Novaes Freire</strong> é pastor na Imel Vila Moraes, supervisor de grupos pequenos e coordenador de retiros evangelísticos.<br /><br />
                            Fabiano é alguém que tem como paixão e propósito o evangelismo e o cuidado com vidas, além de cultivar um coração piedoso e avivado. <br /><br />
                            Fabiano é casado com Silvana e pai de 3 filhos: Lucas, Nicolas e Marcela.
                        </p>
                    </div>
                    <div className='pt-5 text-center text-red-700 hover:text-red-950'>
                        <strong>
                            <button className='text-red text-2xl' onClick={() => setShowModal1(false)}>Fechar</button>
                        </strong>
                    </div>
                </div>
            </Modal>
            <Modal isVisible={showModal2} onClose={() => setShowModal2(false)}>
                <div className='p-6 rounded-md' style={{ backgroundColor: "#fff" }}>
                    <div className='p-7'>
                        <p className='text-pastores-popup'>
                            <strong>Adriano Oliveira</strong> é pastor na Imel Vila Moraes e responsável pelo projeto social Bom Combate, uma ONG que tem como objetivo prover o acesso às aulas de artes marciais com qualidade a qualquer pessoa.<br /><br />
                            Cozinheiro de mão cheia, Adriano possui um coração enorme e paterno com todos. Seus conselhos muitas vezes vêm em forma de letras de música, uma de suas paixões.<br /><br />
                            Adriano é casado com Keity, sua parceira de ministério, de cozinha e de vida
                        </p>
                    </div>
                    <div className='pt-5 text-center text-red-700 hover:text-red-950'>
                        <strong>
                            <button className='text-red text-2xl' onClick={() => setShowModal2(false)}>Fechar</button>
                        </strong>
                    </div>
                </div>
            </Modal>
            <Modal isVisible={showModal3} onClose={() => setShowModal3(false)}>
                <div className='p-6 rounded-md' style={{ backgroundColor: "#fff" }}>
                    <div className='p-7'>
                        <p className='text-pastores-popup'>
                            <strong>Andréa Lima</strong> faz parte da equipe pastoral além de ser discipuladora de mulheres na Imel Vila Moraes.
                            <br /><br />
                            Reconhecida por sua liderança, bom humor e amizade, Andréa possui uma graça especial no cuidado com mulheres.
                            <br /><br />
                            Andréa é mãe de Giovanna e Miguel, e casada com Rodrigo, atual pastor titular.
                        </p>
                    </div>
                    <div className='pt-5 text-center text-red-700 hover:text-red-950'>
                        <strong>
                            <button className='text-red text-2xl' onClick={() => setShowModal3(false)}>Fechar</button>
                        </strong>
                    </div>
                </div>
            </Modal>
            <Modal isVisible={showModal4} onClose={() => setShowModal4(false)}>
                <div className='p-6 rounded-md' style={{ backgroundColor: "#fff" }}>
                    <div className='p-7'>
                        <p className='text-pastores-popup'>
                            <strong>Lídia Paula</strong> é gestora administrativa, líder em educação financeira e professora no Seminário Bíblico Wesleyano, além de compor a equipe pastoral da Imel Vila Moraes.
                            <br /><br />
                            Lídia possui um coração voltado para a obra de Deus e expressa isso através de seu dom de gestão e organização. Graças ao seu talento, tanto os ministérios quanto a própria igreja têm avançado constantemente em organização e cuidado, demonstrando o zelo para com a obra do Senhor.
                            <br /><br />
                            Lídia é esposa de Leandro “Carrá”, um dos fundadores do projeto social Bom Combate. Juntos eles são pais de Isabela.
                        </p>
                    </div>
                    <div className='pt-5 text-center text-red-700 hover:text-red-950'>
                        <strong>
                            <button className='text-red text-2xl' onClick={() => setShowModal4(false)}>Fechar</button>
                        </strong>
                    </div>
                </div>
            </Modal>
            <Modal isVisible={showModal5} onClose={() => setShowModal5(false)}>
                <div className='p-6 rounded-md' style={{ backgroundColor: "#fff" }}>
                    <div className='p-7'>
                        <p className='text-pastores-popup'>
                            <strong>Gilberto Oliveira</strong> ou apenas “Giba” para os mais íntimos é líder de casais, supervisor de grupos pequenos e também compõe a equipe pastoral da Imel Vila Moraes.
                            <br /><br />
                            Giba possui uma personalidade forte e um espírito ensinável. Com uma experiência sólida dada por Deus, Giba busca sempre trazer uma palavra em tom de conselho e reflexão. Seu coração sempre está em busca de aperfeiçoamento da igreja e de seus liderados.
                            <br /><br />
                            Giba é casado com Neide e juntos são pais de Janine.
                        </p>
                    </div>
                    <div className='pt-5 text-center text-red-700 hover:text-red-950'>
                        <strong>
                            <button className='text-red text-2xl' onClick={() => setShowModal5(false)}>Fechar</button>
                        </strong>
                    </div>
                </div>
            </Modal>
        </Fragment>
    );
}

export default Pastores;
