import Hero from "../Components/Home/Hero";
import Agenda from "../Components/Home/Agenda";
import Mensagens from "../Components/Home/Mensagem";
import Missoes from "../Components/Home/missoes";
import SejaMembro from "../Components/Home/Seja-membro";
import Footer from "../Components/Home/Footer";

function Home() {
    return (
        <div className='App'>
            <>
                <Hero />
                <Mensagens />
                <Agenda />
                <Missoes />
                <SejaMembro />
                <Footer />
            </>
        </div>
    );
  }
  
  export default Home;
  
  