import React from 'react';
import '../../Styles/footer.css';
import Facebook from '../../Images/face.svg'
import Instagram from '../../Images/insta.svg'
import Youtube from '../../Images/youtube.svg'

const Footer = () => {
    return (
        <div className="bg-black isolate overflow-hidden bg-primary py-16 lg:py-24 xl:py-24 2xl:py-24 px-10" id="footer">
            <div className='screen-padrao m-auto'>
                <div className='grid grid-cols-1 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-4 gap-4'>
                    <div className="flex grid grid-cols-1">
                        <div>
                            <p className='font-foo-p'>
                                © 2024 Todos os direitos reservados <br/>
                                Igreja Metodista Livre do Brasil de Vila Moraes <br/>
                                CNPJ: 08.185.401/0001-75 <br/>
                                Endereço: Rua Sebastiano Mazzoni, 288 <br/>
                                CEP 04171-000
                            </p>
                        </div>
                        <div className='pt-5'>
                            <p>
                            <span className='text-mail'>Contato:</span> <br />
                            <a href='mailto:contato@imelvilamoraes.com.br' className='mail-foo'>contato@imelvilamoraes.com.br</a>
                            </p>
                        </div>
                        <div className='flex pt-5'>
                            <div className='w-10'>
                                <a href="https://www.facebook.com/imel.vilamoraes1" target="_blank" rel="noreferrer">
                                    <img src={Facebook} alt='Icone do Facebook'/>
                                </a>
                            </div>
                            <div className='w-10'>
                                <a href="https://www.instagram.com/imelvilamoraes/" target="_blank" rel="noreferrer">
                                    <img src={Instagram} alt='Icone do Instagram'/>
                                </a>
                            </div>
                            <div className='w-14'>
                                <a href="https://www.youtube.com/@imelvilamoraes" target="_blank" rel="noreferrer">
                                    <img src={Youtube} alt='Icone do Youtube'/>
                                </a>
                            </div>
                            <div>
                                &nbsp;
                            </div>
                        </div>
                    </div>
                    <div className='flex grid grid-cols-1 max-[768px]:hidden block'>
                        <div>
                            <p>
                                <a href='/sobre-nos' className='link-foo inline-block'>
                                    Sobre nós
                                </a>
                            </p>
                            <p>
                                <a href='/missao-visao-valores' className='link-foo'>
                                    Missão, Visão, Valores
                                </a>
                            </p>
                            <p>
                                <a href='/#quero-ser-membro' className='link-foo'>
                                    Quero ser membro
                                </a>
                            </p>
                            <p>
                                <a href='/missoes-transculturais#atitude' className='link-foo'>
                                    Quero contribuir
                                </a>
                            </p>
                        </div>
                    </div>
                    <div className='flex grid grid-cols-1 max-[768px]:hidden block'>
                        <div>
                            <p className='text-mail font-foo-p'>
                                Links úteis
                            </p>
                            <p>
                                <a href='https://www.metodistalivre.org.br' target="_blank" rel="noreferrer" className='link-foo'>
                                    metodistalivre.org
                                </a>
                            </p>
                            <p>
                                <a href='https://www.ftml.com.br' target="_blank" rel="noreferrer" className='link-foo'>
                                    Seminário Bíblico Wesleyano
                                </a>
                            </p>
                            <p>
                                <a href='https://rodrigolivre.blogspot.com' target="_blank" rel="noreferrer" className='link-foo'>
                                    Blog do Pastor
                                </a>
                            </p>
                        </div>
                    </div>
                    <div className='flex grid grid-cols-1 max-[768px]:hidden block'>
                        <div>
                            <p className='text-mail font-foo-p'>
                                Links úteis
                            </p>
                            <p>
                                <a href='/politica-privacidade' className='link-foo'>
                                    Política de privacidade
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;